import { Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router";
import { sendResetPassword, useAuth } from "../../firebase/auth";

import COLORS from "../../colors";
import { verifyIfUserExists } from "../../firebase/auth";
import { MdMarkEmailRead } from "react-icons/md";

const ForgetPassword = () => {
  const currentUser = useAuth();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  async function send_email() {
    setloading(true);
    let userExists = await verifyIfUserExists(email);

    if (userExists) {
      await sendResetPassword(email);
      setloading(false);
      setEmailSent(true);
      return;
    }
    seterror("Email introuvable");

    setloading(false);
  }

  useEffect(() => {
    if (currentUser?.email && !loading) {
      history.push("/");
    }
  }, [currentUser]);

  return (
    <Container>
      <Grid container spacing={2} py={4}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} p={5} display={{ xs: "none", sm: "none", md: "block" }}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper elevation={0} sx={{ boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }}>
            {emailSent ? (
              <Stack p={4} spacing={2}>
                <Typography gutterBottom variant="h5" fontWeight="bold" color="primary">
                  Email envoyé !!
                </Typography>
                <Typography gutterBottom variant="body1" color={COLORS.black}>
                  Vous allez recevoir un lien par email pour changer de mot de passe.
                </Typography>
                <Stack alignItems="center" flex={1}>
                  <MdMarkEmailRead size={60} color={COLORS.primary} />
                </Stack>
              </Stack>
            ) : (
              <Stack p={4} spacing={2}>
                <Typography gutterBottom variant="h5" fontWeight="bold" color="primary">
                  Entrer votre email svp
                </Typography>
                <Typography gutterBottom variant="body1" color={COLORS.black}>
                  Vous allez recevoir un lien par email pour changer de mot de passe.
                </Typography>
                <TextField
                  label="Email"
                  size="small"
                  fullWidth
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  onFocus={() => seterror("")}
                  error={error !== ""}
                  helperText={error}
                />
                <LoadingButton
                  fullWidth
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={send_email}
                  loading={loading}
                  loadingPosition="end"
                >
                  {loading ? "envoi en cours..." : "Envoyer"}
                </LoadingButton>
              </Stack>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} display={{ xs: "none", sm: "none", md: "block" }}></Grid>
      </Grid>
    </Container>
  );
};

export default ForgetPassword;
