import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";

import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import {
  deleteOneFlight,
  getUserFlights,
  paginateUserFlights,
} from "../../../firebase/Routes/FlightRoutes";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../firebase/auth";
import { LoadingButton } from "@mui/lab";
import { Header } from "./Header";
import { Publication } from "./Publication";
import { PublicationSkeleton } from "./PublicationSkeleton";
import { DeleteFlightDialog } from "./DeleteFlightDialog";

const ITEMS_PER_PAGE = 10;

const MyAnnouces = () => {
  const history = useHistory();
  const currentUser = useAuth();
  const { profilState } = useContext(ProfileDetailsContext);
  const { id } = useParams();

  const [publications, setpublications] = useState([]);
  const [deleteDialog, setdeleteDialog] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState("");

  const [loading, setloading] = useState(true);
  const [loadingMore, setloadingMore] = useState(false);
  const [noMoreItems, setnoMoreItems] = useState(false);

  async function getPublications() {
    const results = await getUserFlights(id, ITEMS_PER_PAGE);
    results.length < 10 && setnoMoreItems(true);
    setpublications(results);
    setloading(false);
  }

  function editPublication(publication) {
    history.push("/create?mode=edit&id=" + publication?.id, {
      edit: true,
      publication,
    });
  }

  function ShowDeleteDialog(id) {
    setItemToBeDeleted(id);
    setdeleteDialog(true);
  }

  async function deleteAFlight() {
    const res = await deleteOneFlight(itemToBeDeleted, currentUser?.email);
    setdeleteDialog(false);
    setItemToBeDeleted("");
  }

  async function addMoreItems() {
    setloadingMore(true);
    const results = await paginateUserFlights(
      id,
      ITEMS_PER_PAGE,
      publications[publications.length - 1]
    );
    // console.log("results.length", results.length);
    (results.length < 10 || results.length === 0) && setnoMoreItems(true);
    setpublications((res) => res.concat(results));
    setloadingMore(false);
  }

  useEffect(() => {
    getPublications();
    return null;
  }, []);

  return (
    <Box>
      <Box py={1}>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          {profilState.icon}
          <Typography fontWeight="bold" variant="h5" color="primary" flexGrow={1}>
            {profilState.label}
          </Typography>
          <Button varaint="contained" color="warning" onClick={() => history.push("/create")}>
            Ajouter
          </Button>
        </Stack>
        {loading ? (
          <Stack spacing={2}>
            <Header />

            {["1", "2", "3", "4"].map((item) => (
              <PublicationSkeleton key={item} />
            ))}
          </Stack>
        ) : (
          <>
            {publications.length > 0 ? (
              <Stack spacing={2} my={3}>
                <Header />
                {publications.map((publication, index) => {
                  if (index % 10 === 0 && index !== 0) {
                    return <Divider key={index} />;
                  }
                  return (
                    <Publication
                      data={publication}
                      key={index}
                      editPublication={editPublication}
                      ShowDeleteDialog={ShowDeleteDialog}
                    />
                  );
                })}
              </Stack>
            ) : (
              <Paper
                sx={{
                  flex: 1,
                  boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
                  p: 2,
                  my: 4,
                  textAlign: "center",
                }}
                elevation={0}
              >
                <Typography color="GrayText">Vous n'avez pas d'annonces.</Typography>
              </Paper>
            )}
          </>
        )}
        {publications.length !== 0 && (
          <LoadingButton
            loading={loadingMore}
            disabled={noMoreItems}
            fullWidth
            endIcon={<MdAdd />}
            variant="outlined"
            onClick={addMoreItems}
          >
            {noMoreItems ? "Fin des résultats" : "Voir plus"}
          </LoadingButton>
        )}
      </Box>
      <DeleteFlightDialog
        deleteDialog={deleteDialog}
        setdeleteDialog={setdeleteDialog}
        deleteFlight={deleteAFlight}
      />
    </Box>
  );
};

export default MyAnnouces;
