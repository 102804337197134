import { Box, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import ProfilDescriptor from "../ViewComponents/ProfilDescriptor";
import Reservation from "../ViewComponents/Reservation";
import ContactInfo from "../ViewComponents/ContactInfo";
import FlightInformations from "../ViewComponents/FlightInformations";
import { useAuth } from "../../firebase/auth";
import COLORS from "../../colors";
import FlightNotFound from "../ViewComponents/FlightNotFound";
import { getAFlight } from "../../firebase/Routes/FlightRoutes";
import { userDetails } from "../../firebase/Routes/UserRoutes";

const Summary = () => {
  return (
    <Paper sx={{ minHeight: "50%", p: 3 }}>
      <Typography>Publicités : </Typography>
      <Divider sx={{ my: 2 }} />
    </Paper>
  );
};

export const ViewContext = createContext();

const View = () => {
  const history = useHistory();
  const { id } = useParams();
  const currentUser = useAuth();
  const [flightState, setflightState] = useState(history.location.state);
  const [notFound, setnotFound] = useState(false);
  const [sender, setsender] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [receiver, setreceiver] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });
  const [adViewed, setadViewed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gpInformations, setgpInformations] = useState({});

  const visitProfil = () => {
    if (flightState.ownerId) {
      // history.push(`/GPprofile/${flightState.ownerId}`);
      history.push(`/profilDetails/${flightState.ownerId}/myProfile`);
    }
  };

  async function fetchDatas() {
    if (id === undefined) history.push("/");
    var flight = history.location.state;
    if (id && history.location.state === undefined) {
      let res = await getAFlight(id);
      // console.log("res", res);
      if (res.error) {
        // console.log("erreur");
        setnotFound(true);
        return;
      }
      flight = res.flight;
      setflightState(flight);
      // console.log("flight", flight);
    }

    var gp = await userDetails(flight?.ownerId);
    // console.log("gp", gp);
    setgpInformations(gp);
    flight?.ownerId && setLoading(false);
  }

  useEffect(() => {
    fetchDatas();
  }, []);

  useEffect(() => {
    currentUser?.email &&
      setsender({
        ...sender,
        firstName: currentUser?.displayName?.split(" ")[0],
        lastName: currentUser?.displayName?.split(" ")[1],
      });
  }, [currentUser]);

  return (
    <ViewContext.Provider
      value={{
        sender,
        setsender,
        receiver,
        setreceiver,
        adViewed,
        setadViewed,
        visitProfil,
        currentUser,
        flightState,
        history,
        loading,
        gpInformations,
      }}
    >
      <Container
        sx={{
          minWidth: "90%",
          mt: 5,
          backgroundColor: COLORS.background,
        }}
      >
        {!notFound ? (
          <Grid container minHeight={300} spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} order={{ xs: 1, sm: 1, md: 0 }}>
              <ProfilDescriptor state={flightState} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack direction="column" spacing={2}>
                <FlightInformations state={flightState} context={ViewContext} />
                <Reservation />
                <ContactInfo state={flightState} loading={loading} ViewContext={ViewContext} />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              display={{ xs: "none", sm: "none", md: "block" }}
            >
              <Summary />
            </Grid>
          </Grid>
        ) : (
          <Box flex={1} display="flex" justifyContent="center" py={5}>
            <FlightNotFound />
          </Box>
        )}
      </Container>
    </ViewContext.Provider>
  );
};

export default View;
