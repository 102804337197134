import {
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import COLORS from "../../../colors";
import { resendEmailVerification } from "../../../firebase/auth";
import { MdCheck, MdUploadFile } from "react-icons/md";
import InformationViewer from "../../ReservationDetailsComponents/InformationViewer";
import { FaIdCard } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { changeDocumentStatus } from "../../../firebase/Routes/UserRoutes";
import { UserHeader } from "./UserHeader";
import { Header } from "./Header";
import { Package } from "./Package";
import { PackageSkeleton } from "./PackageSkeleton";

const Documents = () => {
  const { profilState, user, loading } = useContext(ProfileDetailsContext);

  const [clicked, setclicked] = useState({
    phone: false,
    email: false,
    document: false,
  });
  const [insertedDoc, setinsertedDoc] = useState({});
  const [documentDialog, setdocumentDialog] = useState(false);

  async function sendEmailVerification() {
    await resendEmailVerification();
    setclicked({ ...clicked, email: true });
  }

  async function phoneVerification() {
    setclicked({ ...clicked, phone: true });
  }

  async function sendDocument() {
    setclicked({ ...clicked, document: true });
    const res = await changeDocumentStatus(
      user.email,
      user.userId,
      insertedDoc,
      user?.firstName + " " + user?.lastName
    );
    !res && window.location.reload();
  }

  function handleDocDialogOpen(e) {
    // console.log("e", e);
    setinsertedDoc(e.target.files[0]);
    setdocumentDialog(true);
  }

  function handleDocDialogClose() {
    setinsertedDoc({});
    setdocumentDialog(false);
  }
  const DocDialog = () => {
    return (
      <Dialog open={documentDialog}>
        <DialogTitle>Piéce d'identité</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez confirmer l'envoi du document, nos équipes l'analyseront.
          </DialogContentText>
          <Stack my={4}>
            <InformationViewer
              full
              icon={<FaIdCard />}
              label="Piéce d'identité"
              information={insertedDoc?.name}
              showDivider={false}
            />
          </Stack>
          <LoadingButton
            fullWidth
            variant="contained"
            endIcon={<MdCheck />}
            loading={clicked.document}
            disabled={!insertedDoc?.name}
            onClick={sendDocument}
          >
            Envoyer
          </LoadingButton>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        mb={2}
        display={{ xs: "none", sm: "none", md: "flex" }}
      >
        {profilState.icon}
        <Typography fontWeight="bold" variant="h5" color="primary" flexGrow={1}>
          {profilState.label}
        </Typography>
      </Stack>

      {/* body */}
      <UserHeader />

      <Header />
      {loading ? (
        <Stack spacing={2}>
          {["1", "2", "3", "4"].map((item) => (
            <PackageSkeleton key={item} />
          ))}
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Package
            type="Email"
            verification={user?.email}
            status={user?.emailVerified}
            action={
              clicked.email ? (
                <Typography variant="body2" color="GrayText">
                  Veuillez vérifier vos mails
                </Typography>
              ) : !user?.emailVerified ? (
                <Button
                  sx={{ p: 0, m: 0 }}
                  disabled={clicked.email}
                  size="small"
                  onClick={sendEmailVerification}
                >
                  renvoyer
                </Button>
              ) : (
                <Typography color="green">Vérifié</Typography>
              )
            }
          />
          <Package
            type="Identité"
            verification={
              user?.documentIdentity ?? (
                <Typography color="error" variant="body2">
                  Manquant
                </Typography>
              )
            }
            status={user?.documentVerified}
            action={
              user.documentIdentity && !user?.documentVerified ? (
                <Typography variant="body2" color={COLORS.warning}>
                  Vérification...
                </Typography>
              ) : !user?.documentVerified ? (
                <Button size="small" endIcon={<MdUploadFile />}>
                  <label htmlFor="contained-button">
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="contained-button"
                      type="file"
                      onChange={handleDocDialogOpen}
                    />
                    Insérer
                  </label>
                </Button>
              ) : (
                <Typography color="green">Vérifié</Typography>
              )
            }
          />
          <Package
            type="Téléphone"
            verification={user?.phone}
            status={user?.phoneNumberVerified}
            action={
              clicked.phone ? (
                <Typography variant="body2" color="GrayText">
                  Nos équipes vous contacteront
                </Typography>
              ) : !user?.phoneNumberVerified ? (
                <Button
                  sx={{ p: 0, m: 0 }}
                  disabled={clicked.phone}
                  onClick={phoneVerification}
                  size="small"
                >
                  Relancer
                </Button>
              ) : (
                <Typography color="green">Vérifié</Typography>
              )
            }
          />
          <DocDialog />
        </Stack>
      )}
    </Box>
  );
};

export default Documents;
