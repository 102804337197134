import { Button, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { FaPlaneDeparture } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import LoadingButton from "@mui/lab/LoadingButton";
import FlightSkeleton from "../../FlightSkeleton";
import { useHistory, useParams } from "react-router-dom";
import { FollowGP, UnFollowGP } from "../../../firebase/Routes/UserRoutes";
import { getUserRecentFlights } from "../../../firebase/Routes/FlightRoutes";
import Flight from "../../Flight";
import { AiOutlineUserAdd } from "react-icons/ai";
import { TopHeader } from "./TopHeader";
import { HeaderNumber } from "./HeaderNumber";
import { GPFeedbacks } from "./GPFeedbacks";
import { VerifiedInformations } from "./VerifiedInformations";

const StaticAnnounces = () => {
  const { id } = useParams();
  const [flights, setflights] = useState([]);
  const [loading, setloading] = useState(true);

  async function getRecentFlights() {
    const results = await getUserRecentFlights(id);
    setflights(results);
    setloading(false);
  }

  useEffect(() => {
    const subscribe = getRecentFlights();
    return subscribe;
  }, [id]);

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center" my={3}>
        <FaPlaneDeparture color="grayText" />
        <Typography fontWeight="bold" variant="h6" color="primary" flexGrow={1}>
          Dernieres annonces
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {loading ? (
          ["1", "2", "3"].map((flight) => <FlightSkeleton key={flight} />)
        ) : (
          <Box>
            {flights.length > 0 ? (
              flights.map((flight, index) => <Flight data={flight} key={index} />)
            ) : (
              <Paper
                sx={{
                  flex: 1,
                  boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
                  p: 2,
                  my: 2,
                  textAlign: "center",
                }}
                elevation={0}
              >
                <Typography color="GrayText">Pas d'annonces récentes.</Typography>
              </Paper>
            )}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const Profile = () => {
  const {
    profilState,
    user,
    setuser,
    loading,
    currentUser,
    HeaderInformations,
    setHeaderInformations,
    reservations,
    id,
    getAvatar,
  } = useContext(ProfileDetailsContext);

  const [editing, setediting] = useState(false);

  const [following, setfollowing] = useState(false);
  const history = useHistory();

  //Component
  const ModifyProfile = lazy(() => import("./ModifyProfile"));
  async function follow() {
    if (!currentUser?.uid) {
      history.push("/login");
    } else {
      setfollowing(true);
      const followed = await FollowGP(user?.email, currentUser?.uid);
      let headers = [
        {
          label: "Annonces",
          number: user?.flights?.length,
          key: "announces",
        },
        {
          label: "Abonnés",
          number: user?.followers?.length + 1,
          key: "followers",
        },
        {
          label: "Colis",
          number: reservations.length,
          key: "packages",
        },
      ];
      if (followed) {
        setHeaderInformations(headers);
        var newFollowerState = user.followers;
        newFollowerState.push(currentUser?.uid);
        setuser({
          ...user,
          followers: newFollowerState,
        });
      }
      setfollowing(false);
    }
  }
  async function unFollow() {
    if (!currentUser?.uid) {
      history.push("/login");
    } else {
      setfollowing(true);
      const unfollowed = await UnFollowGP(user?.email, currentUser?.uid);
      let headers = [
        {
          label: "Annonces",
          number: user?.flights?.length,
          key: "announces",
        },
        {
          label: "Abonnés",
          number: user?.followers?.length - 1,
          key: "followers",
        },
        {
          label: "Colis",
          number: reservations.length,
          key: "packages",
        },
      ];
      if (unfollowed) {
        setHeaderInformations(headers);
        setuser({
          ...user,
          followers: user.followers.filter((element) => element !== currentUser?.uid),
        });
      }
      setfollowing(false);
    }
  }

  useEffect(() => {
    async function fetchDatas() {}
    fetchDatas();
  }, [user]);

  const Title = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        {profilState.icon}
        <Typography fontWeight="bold" variant="h5" color="primary" flexGrow={1}>
          {profilState.label}
        </Typography>
        {currentUser?.uid === id ? (
          <Button
            varaint="contained"
            color="warning"
            onClick={() => setediting(true)}
            endIcon={<MdEdit size={15} />}
          >
            Modifier
          </Button>
        ) : (
          <Box>
            {user?.followers.length > 0 && user.followers.includes(currentUser?.uid) ? (
              <LoadingButton
                disabled={loading}
                loading={following}
                fullWidth
                variant="outlined"
                onClick={unFollow}
              >
                Se désabonner
              </LoadingButton>
            ) : (
              <LoadingButton
                disabled={loading}
                endIcon={<AiOutlineUserAdd />}
                loading={following}
                fullWidth
                variant="contained"
                color="primary"
                onClick={follow}
                sx={{ py: 0.5 }}
              >
                S'abonner
              </LoadingButton>
            )}
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <Box>
      {!editing ? (
        <Box py={{ xs: 0, sm: 0, md: 1 }}>
          <Title />

          <Stack
            flex={1}
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            spacing={{ xs: 2, md: 0.5 }}
          >
            <Box flex={1}>
              <TopHeader
                user={user}
                loading={loading}
                getAvatar={getAvatar}
                currentUser={currentUser}
                setuser={setuser}
              />
            </Box>
            {HeaderInformations.length > 0 && (
              <Box flex={1}>
                <HeaderNumber
                  HeaderInformations={HeaderInformations}
                  loading={loading}
                  currentUser={currentUser}
                  id={id}
                />
              </Box>
            )}
          </Stack>
          {/* <StaticInformations loading={loading} user={user} currentUser={currentUser} /> */}
          <StaticAnnounces />
          <GPFeedbacks />
          <Stack>
            <VerifiedInformations user={user} />
          </Stack>
        </Box>
      ) : (
        <Suspense fallback={<text>Chargement</text>}>
          <ModifyProfile setediting={setediting} />
        </Suspense>
      )}
    </Box>
  );
};

export default Profile;
