import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { MdHome } from "react-icons/md";
import NotFoundImage from "../../Images/notfoundflight.svg";

const NotFound = () => {
  return (
    <Container>
      <Stack alignItems="center" mt={-1}>
        <Stack
          alignItems="center"
          spacing={3}
          maxWidth={600}
          my={4}
          p={2}
          bgcolor="white"
          borderRadius={2}
        >
          <Typography variant="h6" color="primary">
            Page introuvable !
          </Typography>
          <img src={NotFoundImage} alt="colis-pro-not-found-image" width="50%" />
          <Button href="/" color="warning" endIcon={<MdHome />}>
            Retour Accueil
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default NotFound;
