import {
  query,
  doc,
  getDoc,
  where,
  getDocs,
  collection,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  startAfter,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../config";
import { UploadFlightTicketImage } from "../Storage";

/**
 * Renvois les informations d'un vol
 * @param {String} id
 * @returns
 */
export const getAFlight = async (id) => {
  var flight;
  var error = false;
  await getDoc(doc(db, "flights", id))
    .then((data) => (flight = { ...data.data(), id: data.id }))
    .catch(() => (error = true));
  return { flight, error };
};

/**
 * Créer un vol
 * @param {*} flight
 * @param {*} email
 * @param {*} image
 * @returns
 */
export const postAflight = async (flight, email, image) => {
  var newFlightId = "";
  await addDoc(collection(db, "flights"), flight)
    .then(async (data) => {
      await updateDoc(doc(db, "users", email), {
        flights: arrayUnion(data.id),
      }).catch((error) => console.log("erreur lors de l'ajout de l'id du vol", error));
      newFlightId = data.id;
    })
    .then(() => UploadFlightTicketImage(newFlightId, image))
    // .then(() => console.log("image stored"))
    .catch((error) => console.log(`erreur creation post`, error));
  return newFlightId;
};

/**
 * Met à jour un vol
 * @param {} flight
 * @param {*} id
 * @returns
 */
export const updateAFlight = async (flight, id) => {
  const docRef = doc(db, "flights", id);
  // console.log("editing ", id);
  let error = id;

  await updateDoc(docRef, flight)
    .then(() => console.log("done"))
    .catch(() => (error = ""));
  return error;
};

/**
 * Supprime un vol
 * @param {*} id
 * @param {*} email
 * @returns
 */
export const deleteOneFlight = async (id, email) => {
  const flightRef = await doc(db, "/flights/" + id);
  let error = false;
  await updateDoc(flightRef, { deleted: true })
    .catch((err) => (error = true))
    .then(async () => {
      // console.log("ownerId :>> ", email);
      const userRef = await doc(db, "/users/" + email);
      await updateDoc(userRef, { flights: arrayRemove(id) }).catch(
        (err) => (error = true) && console.log(err)
      );
    });
  return error;
};

/**
 * Renvoiss les volss pour un user donné
 * @param {*} userId
 * @param {*} limitation
 * @returns
 */
export const getUserFlights = async (userId, limitation = 20) => {
  let flights = [];

  const q = query(
    collection(db, "flights"),
    where("ownerId", "==", userId),
    orderBy("departureDate", "desc"),
    limit(limitation)
  );
  await getDocs(q).then((data) => {
    data.docs.forEach((doc) => flights.push({ ...doc.data(), id: doc.id }));
  });
  return flights;
};

/**
 * renvois les vols paginés pour un user
 * @param {*} userId
 * @param {*} limitation
 * @param {*} lastDoc
 * @returns
 */
export const paginateUserFlights = async (userId, limitation = 10, lastDoc) => {
  let flights = [];
  const lastSnapshotRef = await doc(db, "flights", lastDoc.id);

  let lastSnapshot = await getDoc(lastSnapshotRef);

  const q = query(
    collection(db, "flights"),
    where("ownerId", "==", userId),
    orderBy("departureDate", "desc"),
    startAfter(lastSnapshot),
    limit(limitation)
  );
  let results = await getDocs(q);
  results.docs.forEach((result) => flights.push({ ...result.data(), id: result.id }));

  return flights;
};

/**
 * flights
 */
export const getFeaturedFlight = async () => {
  const q = query(
    collection(db, "flights"),
    where("departureDate", ">=", new Date().toJSON()),
    orderBy("departureDate", "asc"),
    limit(5)
  );
  var results = [];
  await getDocs(q).then((datas) => datas.forEach((data) => results.push(data.data())));
  return results;
};

/**
 * Renvois les vols recents d'un user
 * @param {*} userId l'id du gp
 * @returns retourne les 5 annonces les plus récentes du GP
 */
export const getUserRecentFlights = async (userId) => {
  let flights = [];
  const q = query(
    collection(db, "flights"),
    where("departureDate", ">=", new Date().toJSON()),
    where("ownerId", "==", userId),
    orderBy("departureDate"),
    limit(5)
  );
  await getDocs(q).then((data) => {
    data.docs.forEach(
      (doc) =>
        moment(doc.data().departureDate).isAfter(moment()) &&
        flights.push({ ...doc.data(), id: doc.id })
    );
  });
  return flights;
};

/**
 * renvois les vols qui suivent un vol
 * @param {*} departure
 * @param {*} destination
 * @param {*} date
 * @param {*} lastDoc
 * @returns
 */
export async function queryNextFlights(departure, destination, date, lastDoc) {
  // console.log("lastDoc.id :>> ", lastDoc.id);

  const nextDocuments = [];

  const lastSnapshotRef = await doc(db, "flights/" + lastDoc.id);

  let lastSnapshot = await getDoc(lastSnapshotRef);

  const q = query(
    collection(db, "flights"),
    where("departure.name", "==", departure),
    where("destination.name", "==", destination),
    where("departureDate", ">=", date),
    orderBy("departureDate", "asc"),
    startAfter(lastSnapshot),
    limit(10)
  );

  await getDocs(q).then((datas) =>
    datas.docs.forEach((doc) => {
      nextDocuments.push({ ...doc.data(), id: doc.id });
    })
  );

  return nextDocuments;
}

export async function QueryFlights(departure, destination, date) {
  var exactResults = await getExactResults(departure.name, destination.name, date);
  var transitFlights = [];

  if (exactResults.length === 0) {
    // console.log("fetching transit");
    transitFlights = await SearchForTransit(departure, destination, date);
  }

  // console.log(`exactResults`, exactResults.length);
  return { exact: exactResults, transit: transitFlights };
}

const getExactResults = async (departureCity, destinationCity, departureDate) => {
  var exacts = [];
  const q = query(
    collection(db, "flights"),
    where("departure.name", "==", departureCity),
    where("destination.name", "==", destinationCity),
    where("departureDate", ">=", departureDate),
    orderBy("departureDate", "asc"),
    limit(10)
  );
  await getDocs(q)
    .then((snap) => {
      snap.docs.forEach((doc) => exacts.push({ ...doc.data(), id: doc.id }));
    })
    .catch((error) => console.log(`error`, error));

  return exacts;
};

const getNearResults = async (departureCity, destinationCity, departureCountry, departureDate) => {
  var nearResults = [];
  const q = query(
    collection(db, "flights"),
    where("departure.name", "==", departureCity),
    where("departure.country", "==", departureCountry)
  );
  await getDocs(q)
    .then((snap) => {
      snap.docs.forEach(
        (doc) =>
          doc.data().departure.name != departureCity &&
          nearResults.push({ ...doc.data(), id: doc.id })
      );
    })
    .catch((error) => console.log(`error`, error));

  return nearResults;
};

/**
 * Transit
 */

/**
 * renvois les transits d'un vol
 * @param {*} departure
 * @param {*} destination
 * @param {*} date
 * @returns
 */
const SearchForTransit = async (departure, destination, date) => {
  let results1 = [];
  let results2 = [];
  let transitFlights = [];

  //on cherche 20 vols qui vont à dakar
  const query1 = query(
    collection(db, "flights"),
    where("departure.name", "==", departure.name),
    where("destination.name", "==", "Dakar"),
    where("departureDate", ">=", date),
    orderBy("departureDate", "asc"),
    limit(20)
  );
  // where("destination.name", "in", ["Dakar", "Paris"]),
  await getDocs(query1).then((datas) =>
    datas.docs.forEach((doc) => results1.push({ ...doc.data(), id: doc.id }))
  );

  // console.log("resultats ", departure.name, " :>> ", "Dakar", results1.length);

  if (results1.length === 0) {
    return transitFlights;
  }

  //la plus date minimum
  let minimalDate = results1[0]?.distributionDate;

  // console.log("minimalDate :>> ", new Date(minimalDate));

  const query2 = query(
    collection(db, "flights"),
    where("departure.name", "==", "Dakar"),
    where("destination.name", "==", destination.name),
    where("distributionDate", ">=", minimalDate),
    orderBy("distributionDate", "asc"),
    limit(20)
  );

  await getDocs(query2).then((datas) =>
    datas.docs.forEach((doc) => results2.push({ ...doc.data(), id: doc.id }))
  );

  // console.log("resultats 2:>> ", "Dakar", " :>> ", destination.name, results2.length);

  //Comparaison des dates
  results2.forEach((result2) => {
    results1.forEach((result1) => {
      let delay = moment(result2.departureDate).diff(moment(result1.departureDate), "days");
      if (delay <= 10 && delay > 0) {
        transitFlights.push({ first: result1, second: result2 });
      }
    });
  });

  return transitFlights;
};
