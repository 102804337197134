import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { FaEuroSign, FaHandHolding, FaPlaneDeparture, FaUserAlt } from "react-icons/fa";
import { MdArrowRight, MdExpandMore, MdPhone, MdTextsms } from "react-icons/md";
import COLORS from "../../../colors";
import moment from "moment";
import { GiPayMoney } from "react-icons/gi";
import { InformationViewer } from "./InformationViewer";
import { bagageType } from "./Packages";

export const Package = ({ data, goToReservation }) => {
  const Status = ({ text }) => {
    function getColor() {
      if (text === "ok")
        return {
          color: "#e7f9ed",
          text: "Validée",
          textColor: "green",
        };
      if (text === "delivered")
        return {
          color: "green",
          text: "Délivré",
          textColor: "white",
        };
      if (text === "ko") return { color: "#ffeae9", text: "Annulée", textColor: "red" };
      if (text === "pending")
        return {
          color: "#f9f6e7",
          text: "En attente",
          textColor: "orange",
        };
    }
    return (
      <Typography
        sx={{
          px: 1,
          py: 0.5,
          backgroundColor: getColor().color,
          borderRadius: 5,
        }}
        color={getColor().textColor}
        textAlign="center"
        variant="caption"
        noWrap
      >
        {getColor().text}
      </Typography>
    );
  };

  function getPrice() {
    return data?.finalPrice ? `${data?.finalPrice} ${data.currency}` : data.prices.pricePerKG + " " + data.currency + " /kg";
  }
  function getitemType() {
    return bagageType.filter((element) => element.value === data.itemType)[0];
  }

  return (
    <Paper sx={{ flex: 1, boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }} elevation={0}>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Grid container spacing={1} display="flex" color={COLORS.black}>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Typography variant="body1" fontWeight={600} color="primary" noWrap>
                {data?.departure.name + " - " + data?.destination.name}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
              <Status text={data?.status} />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Typography
                variant="body2"
                fontWeight={500}
                noWrap
                display={{
                  xs: "none",
                  sm: "none",
                  md: "block",
                }}
              >
                {moment(data.reservationDate).format("DD MMM Y ")}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                noWrap
                display={{
                  xs: "block",
                  sm: "block",
                  md: "none",
                }}
              >
                {moment(data.reservationDate).format("DD/MM/YY")}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} display={{ xs: "none", sm: "none", md: "block" }}>
              <Typography variant="body2" fontWeight={500} noWrap color={data.prices ? COLORS.warning : COLORS.black}>
                {getPrice()}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2} columnSpacing={6}>
            <InformationViewer
              icon={<FaUserAlt size={13} color={COLORS.primary} />}
              label="Transporteur"
              information={
                <Link href={"/profilDetails/" + data.gpId + "/myProfile"} underline="hover">
                  <Typography>{data.publisher.firstName + " " + data.publisher.lastName}</Typography>
                </Link>
              }
            />
            <InformationViewer
              icon={<FaPlaneDeparture size={15} color={COLORS.primary} />}
              label="Annonce"
              information={
                <Link href={"/view/" + data.flightId} underline="hover">
                  <Typography>Cliquer ici</Typography>
                </Link>
              }
            />
            <InformationViewer
              icon={<MdPhone size={15} color={COLORS.primary} />}
              label="Appeler"
              information={
                <Link href={"tel:" + data.publisher.phone} underline="hover">
                  <Typography>{data.publisher.phone}</Typography>
                </Link>
              }
            />
            <InformationViewer
              icon={<MdTextsms size={15} color={COLORS.primary} />}
              label="Description"
              information={<Typography>{data.itemDescription}</Typography>}
            />
            <InformationViewer
              icon={<FaHandHolding size={16} color={COLORS.primary} />}
              label="Receveur"
              information={<Typography>{data.reciever.firstName + " " + data.reciever.lastName}</Typography>}
            />
            <InformationViewer
              icon={<GiPayMoney size={18} color={COLORS.primary} />}
              label="Payeur"
              information={
                <Typography>
                  {data.payer === "Envoyeur"
                    ? data.sender.firstName + " " + data.sender.lastName + " (" + data.payer + ")"
                    : data.reciever.firstName + " " + data.reciever.lastName + " (" + data.payer + ")"}
                </Typography>
              }
            />
            <InformationViewer
              icon={<MdTextsms size={15} color={COLORS.primary} />}
              label="Type de produit"
              information={<Typography color={COLORS.primary}>{getitemType().label}</Typography>}
            />
            <InformationViewer
              icon={<FaEuroSign size={15} color={COLORS.primary} />}
              label="Prix par kilo"
              information={<Typography color={COLORS.warning}>{getPrice()}</Typography>}
            />
          </Grid>

          {/* <BoardingPass
                          receiver={data.reciever}
                          sender={data.sender}
                          currency={data.currency}
                          state={{
                              publisher: data.publisher,
                              departure: data.departure,
                              destination: data.destination,
                              id: data.fligthId,
                              prices: data.prices,
                          }}
                      /> */}
          <Stack direction="row" justifyContent="center" spacing={1} mt={1}>
            <Button onClick={() => goToReservation(data)} variant="outlined" color="warning" endIcon={<MdArrowRight />} fullWidth>
              Voir en détails
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
