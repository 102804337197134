import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";

export const DeleteFlightDialog = ({ deleteDialog, setdeleteDialog, deleteFlight }) => {
  return (
    <Dialog open={deleteDialog} onClose={() => setdeleteDialog(false)}>
      <DialogTitle>Confirmer la suppression de l'annonce</DialogTitle>
      <DialogContent>
        <DialogContentText>
          La suppression de l'annonce entraine la clôture de toutes les reservations liées.
        </DialogContentText>
        <Stack direction="row" spacing={3} justifyContent="center" py={2}>
          <Button variant="outlined" color="warning" onClick={() => setdeleteDialog(false)}>
            Non
          </Button>
          <Button variant="contained" color="error" onClick={deleteFlight}>
            Oui
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
