import {
  query,
  doc,
  getDoc,
  where,
  getDocs,
  collection,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  arrayUnion,
  startAfter,
  writeBatch,
  increment,
} from "firebase/firestore";
import { db } from "../config";

/**
 * Reservations
 */

/**
 *
 * @param {String} id id de la reservation
 */
export const GetAReservation = async (reservationId) => {
  const ref = doc(db, "reservations/" + reservationId);
  let result;
  await getDoc(ref)
    .then((data) => (result = { ...data.data(), id: data.id }))
    .catch((error) => console.log("erreur lors du get de la reservation ", error));
  return result;
};

/**
 * Renvois les reservations d'un GP
 * @param {*} id
 * @param {*} lastDoc
 * @returns
 */
export const getUserReservations = async (id, lastDoc) => {
  let q;
  if (lastDoc?.id) {
    const lastSnapshotRef = await doc(db, "reservations", lastDoc.id);

    let lastSnapshot = await getDoc(lastSnapshotRef);

    q = query(collection(db, "reservations"), where("owner", "==", id), orderBy("reservationDate", "desc"), startAfter(lastSnapshot), limit(10));
  } else {
    q = query(collection(db, "reservations"), where("owner", "==", id), orderBy("reservationDate", "desc"), limit(10));
  }
  var reservations = [];
  await getDocs(q)
    .then((datas) => {
      if (datas.size > 0) {
        datas.forEach((data) => reservations.push({ ...data.data(), id: data.id }));
      }
    })
    .catch((error) => console.log(`error while retrieving reservations`, error));
  return reservations;
};

/**
 * Renvois les réservations pour un GP donné
 * @param {String} id
 * @param {*} lastDoc
 * @returns
 */
export const getGPReservations = async (id, lastDoc) => {
  let q = {};
  if (lastDoc?.id) {
    const lastSnapshotRef = await doc(db, "reservations", lastDoc.id);

    let lastSnapshot = await getDoc(lastSnapshotRef);

    q = query(collection(db, "reservations"), where("gpId", "==", id), orderBy("reservationDate", "desc"), startAfter(lastSnapshot), limit(10));
  } else {
    q = query(collection(db, "reservations"), where("gpId", "==", id), orderBy("reservationDate", "desc"), limit(10));
  }
  var reservations = [];
  await getDocs(q)
    .then((datas) => {
      if (datas.size > 0) {
        datas.forEach((data) => reservations.push({ ...data.data(), id: data.id }));
      }
    })
    .catch((error) => console.log(`error while retrieving reservations`, error));
  return reservations;
};

/**
 *
 * @param {*} sender informations sur la personne qui envoit
 * @param {*} reciever informations sur la personne qui recoit
 * @param {*} flight  informations sur le vol
 * @param {*} reservationInfo Detail de la reservation
 * @param {*} owner id de la personne qui reserve
 * @returns l'ID de la reservation crée
 */
export const postUserReservation = async (sender, reciever, flight, reservationInfo, owner) => {
  const docRef = collection(db, "reservations");
  const reservation = {
    sender,
    reciever,
    flightId: flight.id,
    gpId: flight.ownerId,
    prices: flight.prices,
    currency: flight.currency,
    itemDescription: reservationInfo.itemDescription,
    payer: reservationInfo.payer,
    itemType: reservationInfo.itemType,
    owner,
    departure: flight.departure,
    destination: flight.destination,
    publisher: flight.publisher,
    distributionDate: flight.distributionDate,
    status: "pending",
    reservationDate: new Date().toJSON(),
    shipping: flight.destination.name === "Dakar" ? true : false,
  };

  var next = "";
  await addDoc(docRef, reservation)
    .then((data) => (next = data.id))
    .then(() => console.log("reservation done"))
    .catch((err) => console.log("Erreur lors de la reservation ", err));

  return next;
};

/**
 * Permet de supprimer une réservation
 * @param {String} reservationId
 */
export async function deleteUserReservation(reservationId) {
  const docRef = doc(db, "reservations", reservationId);

  await updateDoc(docRef, { deleted: true });
}

/**
 * Permet de valider une reservation et d'ajouter l'idresevation au données du gp
 * @param {*} id la reservation
 * @param {*} status l'état de la réservation
 * @param {*} email l'email du gp
 */
export const changeReservationStatus = async (id, status, email) => {
  const docRef = doc(db, "reservations", id);

  await updateDoc(docRef, { status: status }).then(() => {
    if (status === "ok") {
      const docRef = doc(db, "users", email);
      updateDoc(docRef, { reservations: arrayUnion(id) });
    }
  });
};

/**
 *
 * @param {*} idReservation Id de la reservation
 * @returns {Boolean} si la réservation s'est bien passée
 */
export async function ConfirmReservationDelivery(idReservation) {
  const docRef = doc(db, "reservations", idReservation);
  let result = false;

  await updateDoc(docRef, { status: "delivered", lastModified: new Date() }).then(() => (result = true));

  return result;
}

/**
 * Permet de mettre à jour le prix de la reservation
 * @param {number} price prix de la reservation
 * @param {string} id l'id de la reservation
 * @param {boolean} isFinal l'id de la reservation
 * @returns l'ID de la reservation crée
 */
export async function EditReservationPrice(price, id, isFinal) {
  const docRef = doc(db, "reservations", id);

  let state = isFinal ? { finalPrice: price, paid: true } : { finalPrice: price };

  await updateDoc(docRef, state)
    // .then(() => console.log("price edited"))
    .catch(() => console.log("error while changing price"));
}
/**
 * Permet de demander une réservation
 * @param {string} reservationId
 * @param {string} email
 * @param {string} address
 * @param {number} deliveryPrice
 * @param {Geolocation} position
 * @returns
 */
export async function askForDelivery(reservationId, email, address, deliveryPrice, position) {
  const batchRef = writeBatch(db);
  const reservationRef = doc(db, "reservations", reservationId);
  const userRef = doc(db, "users", email);
  let error = false;

  batchRef.update(userRef, { address: address, freeDelivery: increment(1) });

  batchRef.update(reservationRef, {
    address: address,
    askedForDelivery: true,
    deliveryPrice,
    position,
  });

  await batchRef.commit().catch((err) => console.log("err", err));

  return error;
}

/**
 * Permet de mettre à jour la locatisation d'un client
 * @param {string} reservationId
 * @param {*} position
 */
export async function update_client_location(reservationId, position) {
  const reservationRef = doc(db, "reservations", reservationId);

  await updateDoc(reservationRef, { position: position }).then(() => console.log("position updated"));
}
