//Mes Colis

import { Divider, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import COLORS from "../../../colors";
import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import { useHistory, useParams } from "react-router-dom";
import ReservationViewer from "../ReservationViewer";
import { getUserReservations } from "../../../firebase/Routes/ReservationsRoutes";
import { LoadingButton } from "@mui/lab";
import { AddReservation } from "./AddReservation";
import { PackageSkeleton } from "./PackageSkeleton";
import { DeletedPackage } from "./DeletedPackage";
import { Header } from "./Header";
import { Package } from "./Package";

export const bagageType = [
  { label: "Colis pesé", value: "thing" },
  {
    label: "Téléphone",
    value: "phone",
  },
  { label: "Ordinateur", value: "computer" },
  { label: "Parfum", value: "fragrance" },
  { label: "Documents", value: "paper" },
  { label: "Bijoux", value: "jewel" },
  { label: "Alimentaire", value: "food" },
];

const Packages = () => {
  const { profilState, reservations, setreservations, loading } = useContext(ProfileDetailsContext);

  const { id, subID } = useParams();

  const [editing, setediting] = useState(false);

  const history = useHistory();

  function goToReservation(data) {
    history.push(`/reservationDetails/${data.id}?c=${data.owner}&g=${data.gpId}`);
  }

  const [FilteredReservations, setFilteredReservations] = useState(reservations);
  const [reservationFilter, setreservationFilter] = useState("all");
  const [noMoreItems, setnoMoreItems] = useState(false);
  const [loadingMore, setloadingMore] = useState(false);

  const filters = [
    { label: "Toutes", value: "all" },
    { label: "Validée", value: "ok" },
    { label: "Refusée", value: "ko" },
    { label: "Validation", value: "pending" },
    { label: "Délivrée", value: "delivered" },
    { label: "Arrivé", value: "arrived" },
    { label: "Supprimée", value: "deleted" },
    { label: "Actives", value: "active" },
  ];

  function handleFilter(status) {
    setreservationFilter(status);

    if (status === "deleted" || status === "active") {
      return;
    }

    if (status === "all") {
      setFilteredReservations(reservations);
    } else {
      let newState = [];
      newState = reservations.filter((reservation) => reservation.status === status);
      setFilteredReservations(newState);
    }
  }

  async function addMoreItems() {
    const results = await getUserReservations(id, reservations[reservations.length - 1]);
    // console.log("results.length", results.length);
    (results.length < 10 || results.length === 0) && setnoMoreItems(true);
    setreservations((res) => res.concat(results));
  }

  useEffect(() => {
    reservationFilter === "deleted" &&
      setFilteredReservations(reservations.filter((reservation) => reservation.deleted));
    reservationFilter === "active" &&
      setFilteredReservations(reservations.filter((reservation) => !reservation.deleted));
  }, [reservationFilter]);

  useEffect(() => {
    setFilteredReservations(reservations);
    reservations.length < 10 && setnoMoreItems(true);
  }, [reservations]);

  return (
    <Box>
      {subID ? (
        <ReservationViewer data={history.location.state} loading={false} />
      ) : (
        <Box>
          {!editing ? (
            <Box py={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                mb={2}
                display={{ xs: "none", sm: "none", md: "flex" }}
              >
                {profilState.icon}
                <Typography fontWeight="bold" variant="h5" color="primary" flexGrow={1}>
                  {profilState.label}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Typography color={COLORS.black} variant="body2">
                  Les colis que vous avez envoyés.
                </Typography>
                <TextField
                  size="small"
                  value={reservationFilter}
                  select
                  label="Filtres"
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  {filters.map((filter, index) => (
                    <MenuItem value={filter.value} key={index}>
                      {filter.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              {loading ? (
                <Stack spacing={2}>
                  <Header />

                  {["1", "2", "3", "4"].map((item) => (
                    <PackageSkeleton key={item} />
                  ))}
                </Stack>
              ) : (
                <>
                  {FilteredReservations?.length > 0 ? (
                    <Stack spacing={2} my={3}>
                      <Header />
                      {FilteredReservations.map(
                        //on ajoute une division par 10
                        (reservation, index) => {
                          if (index % 10 === 0 && index !== 0) {
                            return <Divider key={index} />;
                          }
                          return (
                            <>
                              {reservation?.deleted ? (
                                <DeletedPackage data={reservation} key={index} />
                              ) : (
                                <Package
                                  data={reservation}
                                  key={index}
                                  goToReservation={goToReservation}
                                />
                              )}
                            </>
                          );
                        }
                        // (
                        //   <>
                        //     {reservation?.deleted ? (
                        //       <DeletedPackage data={reservation} key={index} />
                        //     ) : (
                        //       <Package
                        //         data={reservation}
                        //         key={index}
                        //         goToReservation={goToReservation}
                        //       />
                        //     )}
                        //   </>
                        // )
                      )}
                    </Stack>
                  ) : (
                    <Paper
                      sx={{
                        flex: 1,
                        boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
                        p: 2,
                        my: 4,
                        textAlign: "center",
                      }}
                      elevation={0}
                    >
                      <Typography color="GrayText">
                        {reservationFilter === "all"
                          ? "Vous n'avez pas de réservations."
                          : "Pas de reservation pour ce filtre"}
                      </Typography>
                    </Paper>
                  )}
                </>
              )}
            </Box>
          ) : (
            <AddReservation setediting={setediting} />
          )}
        </Box>
      )}
      {reservations.length !== 0 && (
        <LoadingButton
          loading={loadingMore}
          disabled={noMoreItems}
          fullWidth
          endIcon={<MdAdd />}
          variant="outlined"
          onClick={addMoreItems}
        >
          {noMoreItems ? "Fin des résultats" : "Voir plus"}
        </LoadingButton>
      )}
    </Box>
  );
};

export default Packages;
