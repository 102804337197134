import { getStorage, uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";
import { setPhotoUrl } from "./auth";
import { app } from "./config";

const storage = getStorage(app);

export async function storeImage(image, folder, displayName, id) {
  var ImageUrl = "";

  const imageRef = ref(storage, folder + displayName?.replace(" ", "_") + "_" + id);
  await uploadBytesResumable(imageRef, image)
    .then(async (snapshot) => {
      await getDownloadURL(snapshot.ref)
        .then((url) => {
          ImageUrl = url;
        })
        .catch(() => console.log("erreur while retrieving url"));
    })
    .catch(() => console.log("erreur lors de l'enregistrement"));
  return ImageUrl;
}

export async function UploadFlightTicketImage(flightId, image) {
  const imageRef = ref(storage, "flight_tickets/" + flightId);
  let error = false;

  await uploadBytesResumable(imageRef, image).catch(() => (error = true));

  return error;
}

export const StoreUserProfilePhoto = async (id, displayName, image) => {
  const imageRef = ref(storage, "profil/" + displayName?.replace(" ", "_") + "_" + id);
  var imageUrl = "";

  await uploadBytesResumable(imageRef, image)
    .then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => (imageUrl = url));
      // console.log(`imageUrl`, imageUrl);
      await setPhotoUrl(imageUrl);
    })
    .catch(() => console.log("erreur lors de l'ajout"));
};

/**
 * Documents
 */
export async function saveDocumentIdentity(image, displayName, id) {
  let identityUrl = "";
  const imageRef = ref(
    storage,
    "identities/" + displayName?.replace(" ", "_") + "_" + id + "." + image.name.split(".").pop()
  );

  await uploadBytesResumable(imageRef, image)
    .then(async (snapshot) => {
      // console.log(`snapshot`, snapshot);
      await getDownloadURL(snapshot.ref).then((url) => (identityUrl = url));
      // console.log(`imageUrl`, identityUrl);
    })
    .catch(() => console.log("erreur lors de l'ajout"));

  return identityUrl;
}

/**
 * Profile photo
 */

export async function getProfileUrl(id, firstName, lastName) {
  let url = "";
  const imageRef = ref(
    storage,
    "profil/" + firstName?.replace(" ", "_") + "_" + lastName?.replace(" ", "_") + "_" + id
  );

  await getDownloadURL(imageRef)
    .then((value) => (url = value))
    .catch((err) => console.log("profile image not found " + err));

  return url;
}
