import { Typography, Grid, Paper } from "@mui/material";
import React from "react";
import COLORS from "../../../colors";

export const Package = ({ type, verification, status, action }) => {
  const Status = ({ text }) => {
    return (
      <Typography
        sx={{
          px: 1,
          py: 0.5,
          backgroundColor: text ? "green" : "lightgray",
          borderRadius: 5,
        }}
        textAlign="center"
        variant="caption"
        noWrap
        color={text ? "white" : "GrayText"}
      >
        {text ? "Vérifié" : "En attente"}
      </Typography>
    );
  };
  return (
    <Paper sx={{ flex: 1, boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }} elevation={0}>
      <Grid container spacing={1} color={COLORS.black} p={2} alignItems="center">
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Typography variant="body1" fontWeight={600} color="primary" noWrap>
            {type}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Typography variant="body2" fontWeight={500} noWrap>
            {verification}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Status text={status} />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Typography variant="body2" fontWeight={500} noWrap>
            {action}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
