import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../colors";

export const PublicationSkeleton = () => {
  return (
    <Paper sx={{ flex: 1, boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }} elevation={0}>
      <Grid container spacing={1} display="flex" color={COLORS.black} p={1}>
        <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
          <Skeleton height={18} width="50%" />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Skeleton height={15} width="50%" />
        </Grid>
        <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
          <Skeleton height={15} width="50%" />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Skeleton height={15} width="50%" />
        </Grid>
      </Grid>
    </Paper>
  );
};
