import { Box, Typography, Stack, Paper, Button, Input, colors } from "@mui/material";
import React, { useContext } from "react";
import { MdOutlineAirplaneTicket } from "react-icons/md";
import COLORS from "../../colors";
import { CreationContext } from "../Pages/Creation";

const FlightTicket = () => {
  const { errors, flightTicketImage, setflightTicketImage } = useContext(CreationContext);

  function handleImageChange(e) {
    // console.log("e", e.target.files);
    if (e.target.files.length > 0) {
      setflightTicketImage(e.target.files[0]);
    } else {
      console.log(e);
    }
  }
  return (
    <Stack direction={{ xs: "column", sm: "row", md: "row" }} mt={2} flex={1}>
      <Box flex={2} pr={2}>
        <Typography gutterBottom>8. Télécharger votre billet d'avion </Typography>
        <Typography variant="body2" color="GrayText">
          Votre billet sera vérifié par notre équipe, s'il n'est pas valable la publication sera
          <b> bloquée</b> .
        </Typography>
      </Box>
      <Paper sx={{ flex: 3, mt: 2 }}>
        <Button
          color="primary"
          fullWidth
          //   disabled={state.dialogLoading === false && state?.flights?.length < 1}
          //   onClick={handleCreateFrom}
        >
          <label htmlFor="contained-button-file">
            <Input
              sx={{ display: "none" }}
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleImageChange}
            />
            <Box p={2} textAlign="center">
              <MdOutlineAirplaneTicket size="40%" color={COLORS.primary} />
              <Typography>Télécharger le billet</Typography>
            </Box>{" "}
          </label>
        </Button>
        {flightTicketImage?.name && (
          <Typography textAlign="center" variant="body2" color={colors.green["800"]}>
            {flightTicketImage.name + " téléchargé"}
          </Typography>
        )}
        {/* <FormHelperText sx={{ textAlign: "center" }}>{getImageSize()}</FormHelperText> */}
      </Paper>
      {errors.ticketImageError && flightTicketImage?.name === undefined && (
        <Typography color="error">Image manquante *</Typography>
      )}
    </Stack>
  );
};

export default FlightTicket;
