import React, { useContext, useState } from "react";

import { Button, ButtonBase, CircularProgress, Divider, Grid, Grow, Paper, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import Flight from "../Flight";
import FlightSkeleton from "../FlightSkeleton";
import { FaPlus, FaRegCalendarTimes } from "react-icons/fa";
import { MdAirplanemodeInactive } from "react-icons/md";
import moment from "moment";

import FilterBar from "../SearchComponents/FilterBar";
import PubBar from "./PubBar";
import { SearchPageContext } from "../Pages/Search";
import SearchImage from "../../Images/search.svg";
import { useParams } from "react-router-dom";
import TransitList from "./TransitList";
import MobileAdvertisement from "../MobileAdvertisement";

const Paginator = ({ datas }) => {
  const { noMoreFlight, showTenMoreFlights } = useContext(SearchPageContext);

  const { departureCity, destinationCity, date } = useParams();

  const [loading, setloading] = useState(false);

  async function handleNextArticles() {
    // console.log("loading 10 more articles");
    setloading(true);
    await showTenMoreFlights(departureCity, destinationCity, date);
    setloading(false);
  }

  return (
    <Box display="flex" justifyContent="center" textAlign="center" alignItems="center" flex={1} my={2}>
      {loading ? (
        <Button disabled endIcon={<CircularProgress size={15} />}>
          Chargement en cours ...
        </Button>
      ) : (
        <>
          {noMoreFlight ? (
            <Stack>
              <MobileAdvertisement />
              <Typography variant="body2" color="grayText">
                Fin des résultats
              </Typography>
            </Stack>
          ) : (
            <Button variant="outlined" endIcon={<FaPlus size={13} />} onClick={handleNextArticles}>
              10 Annonces suivantes
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
const FlightList = () => {
  const { onBoarding, loading, filteredFlight, superlatives, initializing } = useContext(SearchPageContext);

  const Middle = () => {
    return (
      <Box mt={{ xs: 1, md: 0 }}>
        {/* <Grid container spacing={2}> */}

        {onBoarding ? (
          <Stack direction="column" my={2} flex={1} alignItems="center" spacing={2}>
            <Typography variant="h5" fontWeight={600} color="primary">
              Que recherchez-vous?
            </Typography>
            <img src={SearchImage} alt={SearchImage} width="40%" />
          </Stack>
        ) : (
          <>
            {superlatives.length > 0 && <Box minHeight={50} pb={2}></Box>}
            {/* </Grid> */}
            {loading || initializing ? (
              <Box>
                {[1, 2, 3, 4, 5].map((index) => (
                  <FlightSkeleton index={index} />
                ))}
              </Box>
            ) : (
              <Box>
                {filteredFlight.length > 0 ? (
                  <Box>
                    {filteredFlight.map((data, index) => (
                      <Box>
                        <Flight data={data} key={index} />
                        {index + 1 == 10 && filteredFlight.length % 10 > 0 && <Divider sx={{ mb: 3 }}></Divider>}
                      </Box>
                    ))}
                    <Paginator datas={filteredFlight} />
                  </Box>
                ) : (
                  <Paper variant="outlined" sx={{ my: 2 }}>
                    <Stack direction="row" p={3} justifyContent="center" spacing={2} sx={{ background: "white" }}>
                      <Typography variant="body1" color="GrayText">
                        Fin des résultats...
                      </Typography>
                      <FaRegCalendarTimes color="gray" size={20} />
                    </Stack>
                  </Paper>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Grid container spacing={2} p={{ xs: 0, sm: 0, md: 1 }}>
      <Grid
        item
        md={3}
        lg={3}
        sx={{
          display: {
            sm: "none",
            xs: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <FilterBar />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Middle />
        {/* {nearFlights.length > 0 && <TransitList />} */}
        <TransitList />
      </Grid>
      {/* <Grid
        item
        md={2}
        lg={2}
        sx={{
          display: {
            sm: "none",
            xs: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <PubBar />
      </Grid> */}
    </Grid>
  );
};

export default FlightList;
