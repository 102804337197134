import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useContext } from "react";
import { FaCalendarAlt, FaPlane, FaShippingFast } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import { MdPhone } from "react-icons/md";
import QRCode from "react-qr-code";
import COLORS from "../../colors";
// import AnnounceImage from "../../Images/Announce-4.svg";
import AnnounceImage from "../../Images/announce-v5.svg";
import { CreationContext } from "../Pages/Creation";

const QrCodeAndSummary = ({ id }) => {
  const {
    departure,
    destination,
    publisher,
    distributionDate,
    prices,
    state,
    showFinishDialog,
    finishDialogOpen,
    hideDialog,
    history,
    errors,
    contacts,
  } = useContext(CreationContext);

  const QrCodePass = ({ label }) => {
    const url = "https://colis-pro.web.app/view/";
    function getQRCodeValue() {
      return url + id;
    }
    return (
      <Stack direction="column" alignItems="center" spacing={2}>
        <QRCode bgcolor="gray" value={getQRCodeValue()} fgColor={COLORS.primary} size={70} />
      </Stack>
    );
  };
  const Summarydialog = () => {
    return (
      <Dialog open={finishDialogOpen} onClose={() => hideDialog(false)} fullWidth>
        <DialogTitle>Vous y êtes presque!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {state.created
              ? "Publication réussie, partagez cette annonce dans tous vos réseaux sociaux"
              : "Publier l'annonce avant de partagez cette photo"}
          </DialogContentText>
          <Stack ml={2} px={2} direction="row" justifyContent="center" flex={1} mt={2}>
            <Box
              // bgcolor="lightgray"
              sx={{
                backgroundImage: `url(${AnnounceImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            >
              <Stack
                direction="column"
                minHeight={430}
                minWidth={300}
                alignItems="center"
                justifyContent="center"
                spacing={1.4}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography color="primary" fontWeight="bold" fontSize={17}>
                    {departure?.name || "Ville"}
                  </Typography>
                  <FaPlane color={COLORS.warning} size={10} />
                  <Typography color="primary" fontWeight="bold" fontSize={17}>
                    {destination?.name || "Ville"}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FaCalendarAlt color={COLORS.primary} size={12} />
                  <Typography color={COLORS.primary} variant="body2">
                    {moment(distributionDate).format("D MMMM Y")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  textAlign="center"
                  bgcolor={COLORS.primary}
                  p={0.5}
                  borderRadius={1}
                >
                  <IoMdPricetag color="white" size={12} />
                  <Typography color="white" fontWeight="bold" variant="body2">
                    {prices.filter((price) => price.type === "pricePerKG")[0].price}
                  </Typography>
                  <Typography color="white" fontWeight="bold" variant="caption">
                    {state.currency} / KG
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={0.1} alignItems="center">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <MdPhone color={COLORS.primary} size={12} />
                    <Typography color={COLORS.primary} variant="body2">
                      {publisher?.phone}
                    </Typography>
                  </Stack>
                  {contacts.map((contact, index) => (
                    <Stack direction="row" spacing={1} alignItems="center" key={index}>
                      <MdPhone color={COLORS.black} size={12} />
                      <Typography color={COLORS.black} variant="caption">
                        {contact?.phone}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <Stack direction="row" alignItems="center">
                  <QrCodePass />
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="primary" fontWeight="bold" variant="caption">
                    Colis-pro.com
                  </Typography>
                  <FaShippingFast color={COLORS.primary} size={15} />
                </Stack>
              </Stack>
            </Box>
          </Stack>

          <DialogActions>
            {state.created ? (
              <>
                <Button onClick={() => history.push("/view/" + state.createdItemId)}>
                  Voir annonce
                </Button>
                <Button color="warning" onClick={() => history.push("/")}>
                  Quitter
                </Button>
              </>
            ) : (
              <Button color="error" onClick={() => hideDialog(false)}>
                Fermer
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Stack pt={2}>
      {errors.addError && (
        <Typography variant="body2" color="error">
          Erreur, revérifiez les entrées SVP
        </Typography>
      )}
      <Button onClick={() => showFinishDialog(false)}>
        <Box pt={2}>
          <QrCodePass label="Cliquer pour voir l'annonce" />
        </Box>
      </Button>
      <Typography textAlign={"center"} color="primary">
        Cliquer pour voir l'annonce
      </Typography>
      <Summarydialog />
    </Stack>
  );
};

export default QrCodeAndSummary;
