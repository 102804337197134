import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaBuilding, FaCheckCircle, FaIdCard, FaShippingFast, FaUser } from "react-icons/fa";
import { MdClose, MdLocationPin, MdOutlineDeliveryDining, MdPhone, MdSend, MdUploadFile } from "react-icons/md";
import COLORS from "../../colors";
import { useAuth } from "../../firebase/auth";
import { askForDelivery, update_client_location } from "../../firebase/Routes/ReservationsRoutes";
import { userDetails } from "../../firebase/Routes/UserRoutes";
import { ReservationContext } from "../ProfileDetailsComponents/ReservationViewer";
import InformationViewer from "./InformationViewer";

const AskForDelivery = () => {
  const { askForDeliveryDialog, setaskForDeliveryDialog, data } = useContext(ReservationContext);
  const currentUser = useAuth();
  const [client, setclient] = useState({});
  const [loading, setloading] = useState(true);
  const [positionLoading, setpositionLoading] = useState(false);
  const [clientPosition, setclientPosition] = useState({
    long: data?.position?.long || "",
    lat: data?.position?.lat || "",
  });
  const [confirming, setconfirming] = useState(false);
  const [deliveryState, setdeliveryState] = useState({
    address: "",
    error: false,
    done: false,
  });

  async function fetchData() {
    const res = await userDetails(currentUser?.uid);
    setclient(res);
    setdeliveryState({ ...deliveryState, address: client?.address });
    setloading(false);
  }

  function getIdentityStatus() {
    if (client?.documentIdentity) {
      if (client?.documentVerified) return <FaCheckCircle color="green" />;
      else
        return (
          <Typography color={COLORS.warning} variant="body1">
            Vérification...
          </Typography>
        );
    } else {
      return (
        <Button href={process.env.REACT_APP_CLIENT_URL + "/profilDetails/" + client.userId + "/documents"} endIcon={<MdUploadFile />}>
          Insérer
        </Button>
      );
    }
  }

  async function handleConfirm() {
    if (deliveryState.address === "" || clientPosition.lat === "") {
      setdeliveryState({ ...deliveryState, error: true });
      return;
    }
    setconfirming(true);
    const res = await askForDelivery(
      data.id,
      client.email,
      deliveryState.address,
      client?.freeDelivery >= process.env.REACT_APP_FREE_RESERVATIONS_COUNT
        ? process.env.REACT_APP_PAID_RESERVATION_FEE
        : process.env.REACT_APP_FREE_RESERVATION_FEE,
      clientPosition
    );
    !res && window.location.reload(false);
  }

  async function handleGeoPosition(updateToDB = false) {
    setpositionLoading(true);
    await navigator.geolocation.getCurrentPosition((position) => {
      setclientPosition({ long: position.coords.longitude, lat: position.coords.latitude });
      //Mets à jour la base que si demandée
      updateToDB === true && update_client_location(data.id, { lat: position.coords.latitude, long: position.coords.longitude });
      setpositionLoading(false);
    });
  }

  function handleClose() {
    setaskForDeliveryDialog(false);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    currentUser?.uid && fetchData();
  }, [currentUser]);
  return (
    <Dialog open={askForDeliveryDialog} fullWidth onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          Livraison
          <MdClose onClick={() => setaskForDeliveryDialog(false)} />
        </Stack>
      </DialogTitle>
      {loading ? (
        <Stack m={3} flex={1} textAlign="center" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography>Chargement</Typography>
        </Stack>
      ) : !data?.askedForDelivery ? (
        <DialogContent>
          <DialogContentText>Vous devez obligatoirement partager la position de la livraison.</DialogContentText>
          <Stack flex={1} width="100%" my={2}>
            <InformationViewer
              icon={<FaUser size={14} />}
              label="Utilisateur"
              showDivider
              information={<Typography>{client.firstName + " " + client.lastName}</Typography>}
              full
            />
            <InformationViewer icon={<FaIdCard size={14} />} label="Piéce d'identité" showDivider information={getIdentityStatus()} full />
            <InformationViewer
              icon={<MdOutlineDeliveryDining size={15} />}
              label={client?.freeDelivery >= process.env.REACT_APP_FREE_RESERVATIONS_COUNT ? "Livraison" : "Livraisons réduites restantes"}
              showDivider
              information={
                client?.freeDelivery >= process.env.REACT_APP_FREE_RESERVATIONS_COUNT ? (
                  <Typography color={COLORS.black}>1000 Fcfa</Typography>
                ) : (
                  <Typography color="primary">
                    {client?.freeDelivery
                      ? process.env.REACT_APP_FREE_RESERVATIONS_COUNT - client?.freeDelivery
                      : process.env.REACT_APP_FREE_RESERVATIONS_COUNT}
                  </Typography>
                )
              }
              full
            />
            <InformationViewer
              icon={<MdLocationPin size={15} />}
              label={clientPosition?.lat ? "Position : " + Math.ceil(clientPosition.lat) + ", " + Math.ceil(clientPosition.long) : "Position exacte "}
              showDivider
              information={
                <LoadingButton
                  loading={positionLoading}
                  size="small"
                  color={clientPosition.lat !== "" ? "warning" : "primary"}
                  onClick={handleGeoPosition}
                >
                  {clientPosition.lat === "" ? "Partager positon" : "Modifier position"}
                </LoadingButton>
              }
              full
            />
          </Stack>
          <DialogContentText mb={2}>Veuillez décrire ou renseigner votre adresse</DialogContentText>
          <TextField
            size="small"
            value={deliveryState.address}
            fullWidth
            onChange={(e) => setdeliveryState({ ...deliveryState, address: e.target.value })}
            multiline
            InputProps={{
              startAdornment: <MdLocationPin color="gray" style={{ marginRight: 10 }} size={20} />,
            }}
          />

          {client?.documentIdentity && !client?.documentVerified && (
            <Typography color={COLORS.warning} variant="body1">
              Nos équipes vérifient votre piéce d'identité
            </Typography>
          )}

          <LoadingButton
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            loading={confirming}
            endIcon={<MdOutlineDeliveryDining />}
            disabled={
              !client?.documentIdentity ||
              !client?.documentVerified ||
              deliveryState.error ||
              !deliveryState.address ||
              !clientPosition.lat ||
              !clientPosition.long
            }
            onClick={handleConfirm}
          >
            Confirmer
          </LoadingButton>
          <Button
            href={"tel:" + process.env.REACT_APP_CONTACT_PHONE}
            sx={{ mt: 1 }}
            color="success"
            fullWidth
            endIcon={<MdPhone />}
            variant="contained"
          >
            Appelez-nous
          </Button>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText style={{ paddingBottom: 10 }}>Votre demande à été soumise, nos équipes vous contacteront.</DialogContentText>
          <InformationViewer
            icon={<FaBuilding size={15} />}
            label="Adresse"
            information={<Typography color={COLORS.black}>{client?.address}</Typography>}
            full
          />
          <InformationViewer
            icon={<MdLocationPin size={15} />}
            label={clientPosition?.lat ? "Position : " + Math.ceil(clientPosition.lat) + ", " + Math.ceil(clientPosition.long) : "Position exacte "}
            showDivider
            information={
              <LoadingButton
                loading={positionLoading}
                size="small"
                color={clientPosition.lat !== "" ? "warning" : "primary"}
                onClick={() => handleGeoPosition(clientPosition.lat !== "")}
              >
                {clientPosition.lat === "" ? "Partager positon" : "Modifier position"}
              </LoadingButton>
            }
            full
          />
          <Stack my={4} alignItems="center">
            <FaShippingFast color={COLORS.primary} size={40} />
          </Stack>
          <Button href="/contactUs" fullWidth endIcon={<MdSend />} variant="outlined">
            Contactez-nous
          </Button>
          <Button
            href={"tel:" + process.env.REACT_APP_CONTACT_PHONE}
            sx={{ mt: 1 }}
            color="success"
            fullWidth
            endIcon={<MdPhone />}
            variant="contained"
          >
            Appelez-nous
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AskForDelivery;
