import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import COLORS from "../../colors";
import { useAuth } from "../../firebase/auth";
import { MdCheck } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";

const ReservationDialog = ({
  reservationId,
  loading,
  open,
  handleReservation,
  sender,
  reciever,
  isReciever,
  setOpen,
  flight,
  itemType,
  payer,
  title,
}) => {
  const currentUser = useAuth();
  return (
    <Box>
      <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>Vérifez vos informations avant de valider.</DialogContentText>

          <Stack flex={1} mt={2} divider={<Divider light />} spacing={1}>
            <Stack justifyContent="space-between">
              <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                Envoyeur
              </Typography>

              <Typography variant="body1" colors={COLORS.black}>
                {sender?.firstName + " " + sender?.lastName + ", " + sender?.phoneNumber}
              </Typography>
            </Stack>
            {isReciever !== "yes" && (
              <Stack justifyContent="space-between">
                <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                  Receveur
                </Typography>

                <Typography variant="body1" colors={COLORS.black}>
                  {reciever?.firstName + " " + reciever?.lastName + " -- " + reciever?.phoneNumber}
                </Typography>
              </Stack>
            )}
            <Stack justifyContent="space-between">
              <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                Type de colis
              </Typography>

              <Typography variant="body1" colors={COLORS.black}>
                {itemType}
              </Typography>
            </Stack>
            <Stack justifyContent="space-between">
              <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                Prix
              </Typography>

              <Typography variant="body1" colors={COLORS.black}>
                {flight.prices.pricePerKG + flight.currency + " /Kg"}
              </Typography>
            </Stack>
            <Stack justifyContent="space-between">
              <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                Qui paye ?
              </Typography>

              <Typography variant="body1" colors={COLORS.black}>
                {payer}
              </Typography>
            </Stack>
            <Stack justifyContent="space-between" spacing={1}>
              <Typography variant="body1" fontWeight={555} color={COLORS.primary}>
                Paiement
              </Typography>
              <TextField select size="small" defaultValue="Espéces">
                {["Espéces", "Carte", "Wave", "Paypal"].map((mode, index) => (
                  <MenuItem value={mode} key={index} disabled={mode !== "Espéces"}>
                    {mode !== "Espéces" ? mode + " (bientôt)" : mode}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
        </DialogContent>
        {reservationId === "" ? (
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={() => setOpen(false)}
              startIcon={<IoIosArrowBack />}
            >
              Annuler
            </Button>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={loading}
              color="success"
              onClick={handleReservation}
              endIcon={<MdCheck />}
            >
              Réserver
            </LoadingButton>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button fullWidth color="error" onClick={() => setOpen(false)}>
              Fermer
            </Button>
            <Button fullWidth color="primary" href={`/profilDetails/${currentUser?.uid}/packages`}>
              Voir la réservation
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default ReservationDialog;
