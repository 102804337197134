import { Button, Container, Divider, Grid, Link, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { createContext, useContext, useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { IoMailSharp } from "react-icons/io5";
// import loginImage from "../Images/undraw_login_re_4vu2.svg";
import loginImage from "../../Images/undraw_stranded_traveler_pdbw.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router";
import { AuthenticateWithSocials, login, UpdateSocialInfos, useAuth } from "../../firebase/auth";
import { MdOutlineFacebook, MdPhoneAndroid, MdSend, MdSwitchAccount } from "react-icons/md";
import { DesktopDatePicker } from "@mui/lab";
import data from "../../data/test.json";
import COLORS from "../../colors";

const Middle = ({ currentUser }) => {
  const { state, setstate, displayErrors, loading, logIn, connectProvider } = useContext(LoginContext);

  return (
    <Paper elevation={0} sx={{ boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }}>
      <Box px={5} pt={1}>
        <Stack direction="row" justifyContent="center" m={1} alignItems="center">
          <img src={loginImage} alt="login" width="70%" />
        </Stack>
        <Typography gutterBottom variant="h4" fontWeight="bold" color="primary">
          Connexion
        </Typography>
        {displayErrors && (
          <Typography gutterBottom variant="body2" color="error">
            Email ou mot de passe incorrect
          </Typography>
        )}
        <Typography gutterBottom variant="body2" color="error">
          {currentUser?.email}
        </Typography>
      </Box>
      <Box p={5} pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <form onSubmit={logIn}>
              <TextField
                label="Email"
                fullWidth
                size="small"
                type="email"
                error={displayErrors}
                value={state.email}
                InputProps={{
                  endAdornment: <IoMailSharp size={18} color="gray" />,
                }}
                onChange={(e) => setstate({ ...state, email: e.target.value })}
              />
            </form>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <form onSubmit={logIn}>
              <TextField
                label="Mot de passe"
                fullWidth
                size="small"
                type="password"
                error={displayErrors}
                value={state.password}
                InputProps={{
                  endAdornment: <FaLock size={16} color="gray" />,
                }}
                onChange={(e) => setstate({ ...state, password: e.target.value })}
              />
            </form>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <Link href="/forget-password" underline="none">
            <Typography textAlign="end" mb={1} variant="body2">
              mot de passe oublié ?
            </Typography>
          </Link>
          <LoadingButton fullWidth size="medium" color="warning" variant="contained" onClick={logIn} loading={loading} loadingPosition="end">
            {loading ? "Connexion..." : "Se connecter"}
          </LoadingButton>
        </Grid>
        <Box width="100%" py={3}>
          <Divider />
        </Box>
        <Button
          startIcon={<FcGoogle size={20} />}
          fullWidth
          variant="outlined"
          disabled={loading}
          onClick={async () => await connectProvider("google")}
        >
          <Typography>Google</Typography>
        </Button>

        {/* <Button
          startIcon={<MdOutlineFacebook size={20} />}
          fullWidth
          variant="outlined"
          disabled={loading}
          sx={{ mt: 1 }}
          onClick={async () => await connectProvider("facebook")}
        >
          <Typography>Facebook</Typography>
        </Button> */}
        <Box width="100%" py={3}>
          <Divider></Divider>
        </Box>
        <Button startIcon={<MdSwitchAccount size={20} />} fullWidth variant="outlined" disabled={loading} href="/register/start">
          <Typography>S'inscrire</Typography>
        </Button>
        <Box mt={1}>
          <Typography variant="caption">
            En vous inscrivant, vous acceptez nos conditions d'utilisation et notre Politique de confidentialité.
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const MoreInfosSection = ({ user }) => {
  const history = useHistory();
  const [state, setstate] = useState({
    firstName: user?.displayName?.split(" ")[0] || "",
    lastName: "" || user?.displayName?.split(" ")[1],
    sex: "Masculin",
    birthday: null,
    country: "Sénégal",
    phone: "",
  });
  const [submitting, setsubmitting] = useState(false);
  const [errors, seterrors] = useState(false);

  function testErrors() {
    if (
      state.firstName === "" ||
      state.lastName === "" ||
      state.country === "" ||
      state.phone === "" ||
      state.birthday === null ||
      state.sex === ""
    ) {
      return false;
    }
    return true;
  }

  async function handleSendSocialsInfos() {
    if (testErrors() && user?.uid) {
      setsubmitting(true);
      // console.log(state.birthday.toJSON());
      let result = await UpdateSocialInfos(user.email, {
        ...state,
        birthday: state.birthday.toJSON(),
      });
      result ? history.push("/profilDetails/" + user?.uid + "/myProfile") : setsubmitting(false);
    } else {
      // console.log("errors", errors);
      seterrors(true);
    }
  }
  return (
    <Paper elevation={0} sx={{ boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }}>
      <Box p={3}>
        <Typography gutterBottom variant="h5" fontWeight="bold" color="primary">
          Finaliser votre inscription
        </Typography>
        <Typography gutterBottom variant="body2" color={COLORS.black}>
          Vous y êtes presque <strong> {user?.email} </strong>, compléter ces informations une seule fois.
        </Typography>
        <Stack spacing={2} my={2}>
          <TextField
            label="Prénom"
            fullWidth
            size="small"
            value={state.firstName}
            onChange={(e) => setstate({ ...state, firstName: e.target.value })}
            error={errors && state.firstName === ""}
            helperText={errors && state.firstName === "" ? "Champs manquant" : ""}
          />

          <TextField
            label="nom"
            fullWidth
            size="small"
            value={state.lastName}
            onChange={(e) => setstate({ ...state, lastName: e.target.value })}
            error={errors && state.lastName === ""}
            helperText={errors && state.lastName === "" && "Champs manquant"}
          />

          <TextField
            placeholder="sexe"
            label="sexe"
            fullWidth
            select
            size="small"
            value={state.sex}
            onChange={(e) => setstate({ ...state, sex: e.target.value })}
            error={errors && state.sex === ""}
            helperText={errors && state.sex === "" && "Choisisser un sexe"}
          >
            {["Masculin", "Féminin"].map((sexe, index) => (
              <MenuItem key={index} value={sexe}>
                {sexe}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={state.country}
            placeholder="Pays"
            label="Pays"
            fullWidth
            select
            size="small"
            onChange={(e) => setstate({ ...state, country: e.target.value })}
            error={errors && state.country === ""}
            helperText={errors && state.country === "" && "Choisisser un pays"}
          >
            {data.map((country, index) => (
              <MenuItem key={index} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>

          <DesktopDatePicker
            type="date"
            value={state.birthday}
            label="Date de naissance"
            renderInput={(params) => <TextField {...params} fullWidth size="small" error={errors && !state.birthday} />}
            onChange={(value) => {
              setstate({ ...state, birthday: value });
            }}
          />

          <TextField
            label="Télephone"
            fullWidth
            size="small"
            type="tel"
            value={state.phone}
            InputProps={{ endAdornment: <MdPhoneAndroid size={18} color="gray" /> }}
            onChange={(e) => setstate({ ...state, phone: e.target.value })}
            error={errors && state.phone === ""}
            helperText={errors && state.phone === "" && "Renseigner un numéro"}
          />

          <LoadingButton
            variant="contained"
            size="medium"
            color="warning"
            endIcon={<MdSend />}
            fullWidth
            loading={submitting}
            onClick={handleSendSocialsInfos}
          >
            Finaliser
          </LoadingButton>
        </Stack>
      </Box>
    </Paper>
  );
};

export const LoginContext = createContext();

const Login = () => {
  const currentUser = useAuth();
  const history = useHistory();
  const [state, setstate] = useState({
    email: "",
    password: "",
  });
  const [loading, setloading] = useState(false);
  const [displayErrors, setdisplayErrors] = useState(false);
  const [showSocialInfos, setshowSocialInfos] = useState(false);

  const logIn = async (e) => {
    e.preventDefault();
    setdisplayErrors(false);
    setloading(true);
    let res = await login(state.email, state.password, setdisplayErrors);
    setloading(false);
    res !== "" && history.push("/profilDetails/" + res + "/myProfile");
  };

  async function connectProvider(provider) {
    setloading(true);
    try {
      let res = await AuthenticateWithSocials(provider);
      setloading(false);
      res.getSocialInfos ? setshowSocialInfos(true) : history.push("/profilDetails/" + res.uid + "/myProfile");
    } catch (error) {
      setdisplayErrors(true);
      setloading(false);
    }
  }

  useEffect(() => {
    if (currentUser?.email && !loading && !showSocialInfos) {
      history.push("/");
    }
  }, [currentUser]);

  return (
    <LoginContext.Provider value={{ state, loading, setstate, displayErrors, logIn, connectProvider }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} p={5} display={{ xs: "none", sm: "none", md: "block" }}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {showSocialInfos ? <MoreInfosSection user={currentUser} /> : <Middle currentUser={currentUser} />}
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} display={{ xs: "none", sm: "none", md: "block" }}></Grid>
        </Grid>
      </Container>
    </LoginContext.Provider>
  );
};

export default Login;
