import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AdImage from "../../Images/online_ad.svg";

const Advertiser = ({ open, handleClose, context }) => {
  const [timer, settimer] = useState(10);

  useEffect(() => {
    if (timer > 0) {
      const sub = setInterval(() => {
        settimer((res) => (res -= 1));
      }, 1000);
      return () => clearInterval(sub);
    }
  }, [timer]);

  useEffect(() => {
    settimer(10);
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => handleClose(timer)}>
      <DialogTitle>
        {" "}
        {timer === 0
          ? "Fermer pour voir les informations"
          : "Informations dans " + timer + " secondes"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Regarder une courte pub pour dévérouiller les informations
        </DialogContentText>
        <Stack justifyContent="center" alignItems="center" p={5}>
          <img width="90%" src={AdImage} />
        </Stack>
        <DialogContentText>Appuyer sur "Déverouiller" à la fin de la publicité</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Abandonner
        </Button>
        <Button disabled={timer !== 0} onClick={() => handleClose(timer)}>
          Déverouiller
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Advertiser;
