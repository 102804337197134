import { Paper, Stack, Typography, TextField, MenuItem, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";

import COLORS from "../../../colors";
import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import {
  changeReservationStatus,
  getGPReservations,
} from "../../../firebase/Routes/ReservationsRoutes";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ReservationSkeleton } from "./ReservationSkeleton";
import { Header } from "./Header";
import { DeletedReservation } from "./DeletedReservation";
import { Reservation } from "./Reservation";

// const ITEMS_PER_PAGE = 10;

export const bagageType = [
  { label: "Colis pesé", value: "thing" },
  {
    label: "Téléphone",
    value: "phone",
  },
  { label: "Ordinateur", value: "computer" },
  { label: "Parfum", value: "fragrance" },
  { label: "Documents", value: "paper" },
  { label: "Bijoux", value: "jewel" },
  { label: "Alimentaire", value: "food" },
];

const Reservations = () => {
  const { profilState, user, loading, setloading, currentUser } = useContext(ProfileDetailsContext);
  const { id } = useParams();

  const [Reservations, setReservations] = useState([]);
  const [FilteredReservations, setFilteredReservations] = useState([]);
  const [reservationFilter, setreservationFilter] = useState("all");
  const [noMoreItems, setnoMoreItems] = useState(false);
  const [loadingMore, setloadingMore] = useState(false);

  async function getReservations() {
    setloading(true);
    const results = await getGPReservations(id);
    results.length < 10 && setnoMoreItems(true);

    setReservations(results);
    setFilteredReservations(results);
    setloading(false);
  }

  async function addMoreItems() {
    const results = await getGPReservations(id, Reservations[Reservations.length - 1]);
    // console.log("results.length", results.length);
    (results.length < 10 || results.length === 0) && setnoMoreItems(true);
    setReservations((res) => res.concat(results));
  }

  async function rejectPackage(id) {
    await changeReservationStatus(id, "ko", currentUser?.email);
    let newState = Reservations.map((reservation) => {
      if (reservation.id === id) {
        return { ...reservation, status: "ko" };
      } else {
        return reservation;
      }
    });
    setReservations(newState);
  }
  async function validatePackage(id) {
    await changeReservationStatus(id, "ok", currentUser?.email);
    let newState = Reservations.map((reservation) => {
      if (reservation.id === id) {
        return { ...reservation, status: "ok" };
      } else {
        return reservation;
      }
    });
    setReservations(newState);
  }

  const filters = [
    { label: "Toutes", value: "all" },
    { label: "Validée", value: "ok" },
    { label: "Refusée", value: "ko" },
    { label: "Validation", value: "pending" },
    { label: "Délivrée", value: "delivered" },
    { label: "Arrivé", value: "arrived" },
    { label: "Supprimée", value: "deleted" },
    { label: "Actives", value: "active" },
  ];

  function handleFilter(status) {
    setreservationFilter(status);
    if (status === "deleted" || status === "active") {
      return;
    }
    if (status === "all") {
      setFilteredReservations(Reservations);
    } else {
      let newState = Reservations.filter((reservation) => reservation.status === status);
      setFilteredReservations(newState);
    }
  }

  useEffect(() => {
    const subscribe = getReservations();
    return subscribe;
  }, []);

  useEffect(() => {
    reservationFilter === "deleted" &&
      setFilteredReservations(Reservations.filter((reservation) => reservation.deleted));
    reservationFilter === "active" &&
      setFilteredReservations(Reservations.filter((reservation) => !reservation.deleted));
  }, [reservationFilter]);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        mb={2}
        display={{ xs: "none", sm: "none", md: "flex" }}
      >
        {profilState.icon}
        <Typography fontWeight="bold" variant="h5" color="primary" flexGrow={1}>
          {profilState.label}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography color={COLORS.black} variant="body2">
          Les colis que vous devez transporter pour vos clients.
        </Typography>
        <TextField
          size="small"
          value={reservationFilter}
          select
          label="Filtres"
          sx={{ minWidth: 110 }}
          onChange={(e) => handleFilter(e.target.value)}
        >
          {filters.map((filter, index) => (
            <MenuItem value={filter.value} key={index}>
              {filter.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {loading ? (
        <Stack spacing={2}>
          <Header />
          {["1", "2", "3", "4"].map((item) => (
            <ReservationSkeleton key={item} />
          ))}
        </Stack>
      ) : (
        <>
          {FilteredReservations.length > 0 ? (
            <Stack spacing={2} my={3}>
              <Header />
              {FilteredReservations.map((reservation, index) => {
                if (index % 10 === 0 && index !== 0) {
                  return <Divider key={index} />;
                }
                return (
                  <>
                    {reservation?.deleted ? (
                      <DeletedReservation data={reservation} />
                    ) : (
                      <Reservation
                        data={reservation}
                        validatePackage={validatePackage}
                        rejectPackage={rejectPackage}
                        key={index}
                      />
                    )}
                  </>
                );
              })}
            </Stack>
          ) : (
            <Paper
              sx={{
                flex: 1,
                boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
                p: 2,
                my: 4,
                textAlign: "center",
              }}
              elevation={0}
            >
              <Typography color="GrayText">
                {reservationFilter === "all"
                  ? "Vous n'avez pas de réservations."
                  : "Pas de reservation pour ce filtre"}
              </Typography>
            </Paper>
          )}
        </>
      )}
      {Reservations.length !== 0 && (
        <LoadingButton
          loading={loadingMore}
          disabled={noMoreItems}
          fullWidth
          endIcon={<MdAdd />}
          variant="outlined"
          onClick={addMoreItems}
        >
          {noMoreItems ? "Fin des résultats" : "Voir plus"}
        </LoadingButton>
      )}
    </Box>
  );
};

export default Reservations;
