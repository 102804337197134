import { addDoc, collection } from "firebase/firestore";
import { db } from "../config";

export async function sendMailToManager(userID, mail) {
  let success = false;
  await addDoc(collection(db, "mails"), { userID, ...mail })
    .then((success = true))
    .catch((err) => console.log(err, "Error while sending mail"));
  return success;
}
