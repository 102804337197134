import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FaIdCard, FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdInfo } from "react-icons/md";
import VerifiedCondition from "../../ViewComponents/VerifiedCondition";

export const VerifiedInformations = ({ user }) => {
  return (
    <Stack>
      <Stack direction="row" spacing={2} alignItems="center" my={2}>
        <MdInfo color="grayText" />
        <Typography fontWeight="bold" variant="h6" color="primary" flexGrow={1}>
          Informations validées
        </Typography>
      </Stack>
      <Box>
        <VerifiedCondition
          label="Piéce d'identité"
          icon={<FaIdCard />}
          condition={user?.documentVerified}
        />

        <VerifiedCondition label="Email" icon={<MdEmail />} condition={user?.emailVerified} />
        <VerifiedCondition
          label="Téléphone"
          icon={<FaPhoneAlt />}
          condition={user?.phoneNumberVerified}
        />
      </Box>
    </Stack>
  );
};
