import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { MdArrowRight, MdCancel, MdCheck, MdExpandMore, MdPhone } from "react-icons/md";
import COLORS from "../../../colors";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { bagageType } from "./Reservations";

export const Reservation = ({ data, validatePackage, rejectPackage }) => {
  const [state, setstate] = useState({ loading: false, action: "" });
  const history = useHistory();

  const Status = ({ text }) => {
    const [state, setstate] = useState(text);
    function getColor() {
      if (state === "ok")
        return {
          color: "#e7f9ed",
          text: "Validée",
          textColor: "green",
        };

      if (text === "delivered")
        return {
          color: "green",
          text: "Délivré",
          textColor: "white",
        };

      if (state === "ko")
        return {
          color: "#ffeae9",
          text: "Refusée",
          textColor: "red",
        };
      if (state === "pending")
        return {
          color: "#f9f6e7",
          text: "En attente",
          textColor: "orange",
        };
    }
    return (
      <Typography
        sx={{
          px: 1,
          py: 0.5,
          backgroundColor: getColor().color,
          borderRadius: 5,
        }}
        color={getColor().textColor}
        textAlign="center"
        variant="caption"
        noWrap
      >
        {getColor().text}
      </Typography>
    );
  };

  function getitemType() {
    return bagageType.filter((element) => element.value === data.itemType)[0];
  }
  function getPrice() {
    return data?.finalPrice
      ? `${data?.finalPrice} ${data.currency}`
      : data.prices.pricePerKG + " " + data.currency + " /kg";
  }

  async function handleValidate() {
    setstate({ loading: true, action: "validate" });
    await validatePackage(data?.id);
    await setTimeout(() => {
      setstate({ loading: false, action: "" });
      history.push(`/reservationDetails/${data.id}?c=${data.owner}&g=${data.gpId}`);
    }, 2000);
  }
  async function handleReject() {
    setstate({ loading: true, action: "reject" });
    await rejectPackage(data?.id);
    await setTimeout(() => {
      setstate({ loading: false, action: "" });
      history.push(`/reservationDetails/${data.id}?c=${data.owner}&g=${data.gpId}`);
    }, 2000);
  }
  return (
    <Paper
      sx={{
        flex: 1,
        boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
      }}
      elevation={0}
    >
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container spacing={1} display="flex" color={COLORS.black}>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Typography variant="body1" fontWeight={600} color="primary" noWrap>
                {data?.departure.name + " - " + data?.destination.name}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
              <Status text={data.status} />
            </Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={3}
              lg={3}
              xl={3}
              display={{
                xs: "none",
                sm: "none",
                md: "block",
              }}
            >
              <Typography variant="body2" fontWeight={500} noWrap>
                {data.sender.firstName + " " + data.sender.lastName}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Typography variant="body2" fontWeight={500} noWrap color={COLORS.warning}>
                {getPrice()}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Client :{" "}
              </Typography>

              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Vol :{" "}
              </Typography>

              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Description :
              </Typography>

              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Receveur :{" "}
              </Typography>
              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Payeur :{" "}
              </Typography>
              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Type de produit :{" "}
              </Typography>
              <Typography fontWeight="bold" variant="body1" color={COLORS.black}>
                Prix par Kilo :{" "}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Link href={"/profilDetails/" + data.owner + "/myProfile"} underline="hover">
                <Typography>{data.sender.firstName + " " + data.sender.lastName}</Typography>
              </Link>
              <Link href={"/view/" + data.flightId} underline="hover">
                <Typography>Cliquer ici</Typography>
              </Link>

              <Typography>{data.itemDescription}</Typography>

              <Link href={"tel:" + data.reciever.phoneNumber} underline="hover">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>{data.reciever.firstName + " " + data.reciever.lastName}</Typography>
                  <FaPhone size={13} />
                </Stack>
              </Link>
              <Typography>
                {data.payer === "Envoyeur"
                  ? data.sender.firstName + " " + data.sender.lastName + " (" + data.payer + ")"
                  : data.reciever.firstName +
                    " " +
                    data.reciever.lastName +
                    " (" +
                    data.payer +
                    ")"}
              </Typography>
              <Typography color={COLORS.black}>{getitemType().label}</Typography>
              <Typography color={COLORS.warning}>{getPrice()}</Typography>
            </Stack>
          </Stack>
          {data.status === "pending" && (
            <>
              <Stack direction="row" spacing={1} mb={2} mt={4} justifyContent="center">
                <LoadingButton
                  loading={state.loading && state.action === "reject"}
                  color="error"
                  variant="contained"
                  endIcon={<MdCancel />}
                  onClick={() => handleReject()}
                  fullWidth
                >
                  Refuser
                </LoadingButton>
                <LoadingButton
                  loading={state.loading && state.action === "validate"}
                  color="success"
                  variant="contained"
                  endIcon={<MdCheck />}
                  onClick={() => handleValidate()}
                  fullWidth
                >
                  Valider
                </LoadingButton>
              </Stack>
              <Button
                variant="outlined"
                color="warning"
                href={`/reservationDetails/${data.id}?c=${data.owner}&g=${data.gpId}`}
                fullWidth
                endIcon={<MdArrowRight />}
              >
                détails
              </Button>
            </>
          )}
          {data.status !== "pending" && (
            <Stack direction={{ xs: "column", md: "row" }} spacing={2} mt={3}>
              <Button
                variant="outlined"
                href={`tel:${data.sender.phoneNumber}`}
                fullWidth
                endIcon={<MdPhone />}
              >
                Appeler
              </Button>
              <Button
                variant="outlined"
                color="success"
                href={`whatsapp://send?text=Bonjour ${data.sender.firstName}, je vous contacte suite à votre réservation sur Colis-pro.&phone=${data.sender.phoneNumber}`}
                fullWidth
                endIcon={<FaWhatsapp />}
              >
                whatsapp
              </Button>
              <Button
                variant="outlined"
                color="warning"
                href={`/reservationDetails/${data.id}?c=${data.owner}&g=${data.gpId}`}
                fullWidth
                endIcon={<MdArrowRight />}
              >
                détails
              </Button>
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
