const COLORS = {
  primary: "#535591",
  secondary: "#535591",
  warning: "#e76f51",
  red: "#a74e51",
  yellow: "#ffaf47",
  background: "#F6F6F9",
  black: "#555659",
};

export default COLORS;
