import { LoadingButton } from "@mui/lab";
import { Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MdCheck, MdHome, MdMarkEmailRead } from "react-icons/md";
import { useHistory } from "react-router-dom";
import COLORS from "../../colors";
import { handlePasswordReset, resendEmailVerification, useAuth, verifyEmail } from "../../firebase/auth";
import emailVerification from "../../Images/emailVerification.svg";
import forgotPassword from "../../Images/forgotPassword.svg";
import { RiLockPasswordLine } from "react-icons/ri";
import { userDetails } from "../../firebase/Routes/UserRoutes";

const Verification = () => {
  const history = useHistory();
  const currentUser = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get("mode");
  const oobCode = queryParams.get("oobCode");
  const email = queryParams.get("email");
  const [passwordChanged, setpasswordChanged] = useState(false);
  const [user, setuser] = useState({});

  const [state, setstate] = useState({
    loading: false,
    error: false,
    verified: false,
    resent: false,
    password1: "",
    password2: "",
  });

  async function resendVerification() {
    setstate({ ...state, loading: true });
    await resendEmailVerification();

    await setTimeout(() => {
      setstate({ ...state, loading: false, resent: true });
    }, 1500);
  }

  async function verifyUser() {
    setstate({ ...state, loading: true });
    await verifyEmail(oobCode);
    setstate({ ...state, loading: false });
    window.location.reload(false);
  }

  async function reset_password() {
    if (state.password1.length > 7 && state.password1 === state.password2) {
      console.log("reseting password");
      await handlePasswordReset(oobCode, state.password1).then(() => setpasswordChanged(true));
    } else {
      setstate({ ...state, error: true });
    }
  }

  useEffect(() => {
    async function fetchUser() {
      let res = await userDetails(currentUser?.uid);
      setuser(res);
    }
    currentUser?.uid && fetchUser();
  }, [currentUser]);
  const Email = () => {
    return (
      <Box p={2}>
        <Paper>
          {currentUser?.emailVerified && user?.emailVerified ? (
            <Box p={2}>
              <Stack p={4} spacing={2}>
                <Typography gutterBottom variant="h5" fontWeight="bold" color="primary">
                  Email vérifié !!
                </Typography>
                <Typography gutterBottom variant="body1" color={COLORS.black}>
                  Vous pouvez maintenant créer des annonces et demander des livraisons à 1000 frc.
                </Typography>
                <Stack alignItems="center" flex={1}>
                  <MdMarkEmailRead size={60} color={COLORS.primary} />
                </Stack>
              </Stack>
              <Button variant="contained" fullWidth endIcon={<MdHome />} onClick={() => history.push("/")}>
                Accueil
              </Button>
            </Box>
          ) : (
            <Stack display="flex" alignItems="center" py={4} px={2}>
              <img alt={emailVerification} src={emailVerification} width="40%" />
              <Typography py={2} variant="h6" fontWeight={600} color={COLORS.black}>
                Cliquer ici pour verifier le compte
              </Typography>
              <Typography variant="body2">Email : {currentUser?.email}</Typography>
              <LoadingButton
                variant="contained"
                color="warning"
                loading={state.loading}
                endIcon={state.verified && <MdCheck />}
                sx={{ my: 3 }}
                onClick={verifyUser}
              >
                Vérifier le compte
              </LoadingButton>

              <LoadingButton loading={state.loading} disabled={state.resent} onClick={resendVerification}>
                {state.resent ? "Renvoyer un code" : "Code renvoyé"}
              </LoadingButton>
            </Stack>
          )}
        </Paper>
      </Box>
    );
  };

  const PasswordChangedUI = () => {
    return (
      <Stack p={4} spacing={2} minWidth="20vw">
        <Typography gutterBottom variant="h5" fontWeight="bold" color="primary">
          Mot de passe réinitialisé
        </Typography>
        <Typography gutterBottom variant="body1" color={COLORS.black}>
          Vous pouvez désormais vous connecter.
        </Typography>
        <Stack alignItems="center" flex={1}>
          <RiLockPasswordLine size={60} color={COLORS.primary} />
        </Stack>
      </Stack>
    );
  };

  return (
    <Container>
      <Stack direction="row" justifyContent="center" py={5}>
        {mode === "verifyEmail" ? (
          <Email />
        ) : (
          <Box p={2}>
            <Paper>
              {passwordChanged ? (
                <PasswordChangedUI />
              ) : (
                <Stack display="flex" alignItems="center" py={4} px={2}>
                  <img alt={forgotPassword} src={forgotPassword} width="40%" />
                  <Typography py={2} variant="h6" fontWeight={600} color={COLORS.black}>
                    Réinitialiser votre mot de passe
                  </Typography>
                  <Stack flex={1} spacing={2} minWidth={250}>
                    <TextField
                      key="password1"
                      label="Mot de passe"
                      error={state.error}
                      helperText={state.error && "Mot de passe different ou inférieur à 8 caractéres"}
                      type="password"
                      size="small"
                      fullWidth
                      onFocus={() => setstate({ ...state, error: false })}
                      value={state.password1}
                      onChange={(e) => setstate({ ...state, password1: e.target.value })}
                    />
                    <TextField
                      label="Confirmer Mot de passe"
                      error={state.error}
                      helperText={state.error && "Mot de passe different ou inférieur à 8 caractéres"}
                      type="password"
                      size="small"
                      fullWidth
                      onFocus={() => setstate({ ...state, error: false })}
                      value={state.password2}
                      onChange={(e) => setstate({ ...state, password2: e.target.value })}
                    />
                  </Stack>
                  <Button
                    variant="contained"
                    color="warning"
                    endIcon={state.verified && <MdCheck />}
                    sx={{ my: 3, minWidth: 250 }}
                    onClick={reset_password}
                  >
                    Valider
                  </Button>
                </Stack>
              )}
            </Paper>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default Verification;
