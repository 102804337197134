import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import COLORS from "../../../colors";

export const HeaderNumber = ({ HeaderInformations, loading, id }) => {
  return (
    <Box>
      <Grid container spacing={2} flex={1}>
        {HeaderInformations.map((data) => (
          // <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={data.key}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={data.key}>
            <Paper
              elevation={0}
              sx={{
                boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
              }}
            >
              <Box p={2} textAlign="center">
                <Typography variant="h6" color={COLORS.warning}>
                  {loading ? (
                    <Skeleton width="100%" />
                  ) : (
                    <>{data.number >= 10 ? data.number : "0" + data.number}</>
                  )}
                </Typography>
                <Typography variant="caption" color="GrayText">
                  {loading ? <Skeleton width="100%" /> : data.label}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
