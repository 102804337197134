// Import the functions you need from the SDKs you need
import {
  query,
  doc,
  where,
  getDocs,
  collection,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../config";
import { saveDocumentIdentity } from "../Storage";

/**
 * permet à un user authentifié de suivre un GP
 * @param {string} emailGP
 * @param {string} followerId ID de la personne qui suit le GP
 * @returns {boolean} followed
 */
export async function FollowGP(emailGP, followerId) {
  const ref = doc(db, "users", emailGP);
  var followed = false;
  await updateDoc(ref, {
    followers: arrayUnion(followerId),
  })
    .then(() => (followed = true))
    // .then(() => console.log("follow done"))
    .catch(() => console.log("erreur lors de l'abonnement"));
  return followed;
}
/**
 * Permet supprime un GP de la liste des followers
 * @param {String} email
 * @param {String} followerId
 * @returns
 */
export async function UnFollowGP(email, followerId) {
  const ref = doc(db, "users", email);
  var unfollowed = false;
  await updateDoc(ref, {
    followers: arrayRemove(followerId),
  })
    .then(() => (unfollowed = true))
    // .then(() => console.log("unfollow done"))
    .catch(() => console.log("erreur lors de l'abonnement"));
  return unfollowed;
}

export async function userDetails(id) {
  const q = query(collection(db, "users"), where("userId", "==", id), limit(1));
  let user;
  await getDocs(q)
    .then((data) => {
      user = data.docs[0].data();
    })
    .catch((erreur) => console.log("erreur", erreur));
  return user;
}

/*
    user details
  */

export const UpdateUserDetails = async (firstName, lastName, email, address) => {
  const docRef = doc(db, "users/" + email);

  await updateDoc(docRef, { firstName, lastName, address }).catch((error) =>
    console.log(`error`, error)
  );
};

export async function savePhotoUrl(photoUrl, email) {
  const docRef = doc(db, "users", email);

  await updateDoc(docRef, { photoUrl });
}

/**
 * followers
 */

/**
 * Renvoit les personnes qui suivent l'utilisateur connecté
 * @param {Array(int)} ids Tableau d'identifiants des personnes qui suivent le client
 */
export async function getFollowers(ids) {
  const q = query(collection(db, "users"), where("userId", "in", ids));
  let followers = [];
  await getDocs(q).then((datas) =>
    datas.forEach((data) => followers.push({ ...data.data(), id: data.id }))
  );
  return followers;
}
/**
 * Renvoit les personnes suivies l'utilisateur connecté
 * @param {int} id Identifiant de la personne qui suit
 */
export async function getFollowedPeople(id) {
  const q = query(collection(db, "users"), where("followers", "array-contains", id));
  let results = [];
  await getDocs(q).then((datas) =>
    datas.forEach((data) => results.push({ ...data.data(), id: data.id }))
  );
  return results;
}

/**
 * Documents
 */
export async function changeDocumentStatus(email, id, document, displayName) {
  const docRef = doc(db, "users", email);

  let error = false;

  let url = await saveDocumentIdentity(document, displayName, id);

  await updateDoc(docRef, {
    documentIdentity: "identity",
    documentVerified: false,
    identityUrl: url,
  }).catch((err) => (error = error !== ""));

  return error;
}
