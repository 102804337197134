import { Button, Container, Grid, IconButton, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaSuitcase, FaUserAlt } from "react-icons/fa";
import SearchBar from "../SearchBar";
import { SearchPageContext } from "./Search";
import COLORS from "../../colors";
import { MdAdd, MdLocalPhone, MdMail, MdMessage, MdOutlineDeliveryDining, MdOutlineLocationOn, MdSecurity } from "react-icons/md";
import { useAuth } from "../../firebase/auth";
import PresentationImage from "../../Images/HomeImage.svg";

// const PresentationImage =
//   "https://firebasestorage.googleapis.com/v0/b/fir-c69a6.appspot.com/o/websiteImage%2FHomeImage.svg?alt=media&token=6ed815ca-143a-48bf-a3eb-69d1ed86f2ba";
const SearchPaper = () => {
  return (
    <Box mt={{ xs: -8, sm: -8, md: -6 }}>
      <Paper
        sx={{
          borderColor: "#E5E5E5",
          boxShadow: { sm: "none", md: "1px 1px 5px 2px #494aa225" },
          width: { xs: "100%", sm: "100%", md: "90%" },
          marginX: "auto",
          backgroundColor: {
            xs: "inherit",
            sm: "inherit",
            md: "white",
          },
        }}
        elevation={0}
      >
        <SearchBar gotoPage={true} />
      </Paper>
    </Box>
  );
};

const OurValues = () => {
  const values = [
    {
      label: "Zéro commission",
      description:
        "Afin de garantir des prix bas, Colis Pro ne vous prend aucune commission, si vous le souhaitez, vous encaissez la totalité de vos revenus.",
      icon: <GiTakeMyMoney size={60} color={COLORS.warning} />,
    },
    {
      label: "Livraisons à 1000 FCFA",
      description:
        "Pour vos 5 premières commandes vers Dakar, la livraison est à 1000 F/kg du GP à votre domicile. A ne pas confondre avec les frais de covalisage",
      icon: <MdOutlineDeliveryDining size={60} color={COLORS.warning} />,
    },
    {
      label: "Sécurité Garantie",
      description:
        "Tous les GP qui transportent vos colis sont identifiés (Passport, appel téléphonique...), les clients aussi sont identifiés pour protéger les GP.",
      icon: <MdSecurity size={60} color={COLORS.warning} />,
    },
  ];
  return (
    <Grid container px={2} py={7} spacing={2}>
      {values.map((value, index) => (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} textAlign="center" key={index}>
          <IconButton size="large">{value.icon}</IconButton>
          <Typography fontWeight="bold" color="primary">
            {value.label}
          </Typography>
          <Typography variant="body1" color="GrayText">
            {value.description}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
// const FeaturedFlight = () => {
//   const [topFlights, settopFlights] = useState([]);
//   const [loading, setloading] = useState(true);

//   async function getTopFlights() {
//     settopFlights(await getFeaturedFlight());
//     setloading(false);
//   }
//   useEffect(() => {
//     getTopFlights();
//   }, []);
//   return (
//     <Box my={3}>
//       <Typography fontWeight="bold" variant="h5" color={COLORS.black}>
//         Dernieres publications
//       </Typography>
//       <Box my={2} mx={{ xs: 0, sm: 0, md: 10, lg: 10, xl: 10 }}>
//         {loading ? <FlightSkeleton /> : <></>}
//       </Box>
//     </Box>
//   );
// };

const EmbbedVideo = ({ embedId, step, title, description, button, flip = 1 }) => {
  return (
    <Grid container px={2} py={3} spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display="flex" alignItems="center" justifyContent="center" py={3}>
        <Stack flex={1} mt={{ xs: 2, md: 0 }}>
          <iframe
            width="100%"
            height="350"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} order={{ xs: -1, sm: -1, md: flip }}>
        <Stack spacing={1} my={4}>
          <Typography variant="body1" color={COLORS.warning}>
            {step}
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="primary">
            {title}
          </Typography>
          <Typography variant="body2" color={COLORS.black}>
            {description}
          </Typography>
        </Stack>
        {button}
      </Grid>
    </Grid>
  );
};

const ContactForm = () => {
  const contactIcons = [
    {
      icon: <MdOutlineLocationOn size={50} color={COLORS.warning} />,
      label: process.env.REACT_APP_LOCATION,
      link: "https://www.google.com/maps/place/Grand+Yoff,+Dakar,+S%C3%A9n%C3%A9gal/@14.7369278,-17.4660669,15z/data=!3m1!4b1!4m6!3m5!1s0xec10d49d564868f:0xc99d05c0d4618ca6!8m2!3d14.7328281!4d-17.4561914!16s%2Fm%2F04zzqkj",
    },
    {
      icon: <MdLocalPhone size={50} color={COLORS.warning} />,
      label: process.env.REACT_APP_CONTACT_PHONE,
      link: "tel:" + process.env.REACT_APP_CONTACT_PHONE,
    },
    {
      icon: <MdMail size={50} color={COLORS.warning} />,
      label: process.env.REACT_APP_MAIL,
      link: "mailto:" + process.env.REACT_APP_MAIL,
    },
  ];
  return (
    <Stack spacing={4} py={4} mx={3}>
      <Stack spacing={2}>
        <Typography textAlign="center" variant="h4" fontWeight="bold" color="primary">
          Plus d'informations ?
        </Typography>
        <Typography textAlign="center" variant="body1" color={COLORS.black}>
          Contactez-nous et notre equipe répondra à toutes vos questions.
        </Typography>
      </Stack>
      <Stack direction={{ xs: "column", md: "row" }} color={COLORS.black} spacing={2}>
        {contactIcons.map((contact) => (
          <Link href={contact.link} target="_blank" underline="none" sx={{ ":hover": { bgcolor: "white", borderRadius: 3 } }} flex={1}>
            <Stack alignItems="center" spacing={2} p={2}>
              {contact.icon}
              <Typography fontWeight={555}>{contact.label}</Typography>
            </Stack>
          </Link>
        ))}
      </Stack>
      <Link href="contactUs" textAlign="center" underline="hover">
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Typography>Page de contact</Typography>
          <MdMessage size={25} />
        </Stack>
      </Link>
    </Stack>
  );
};

const Home = () => {
  const [flights, setflights] = useState([]);
  const currentUser = useAuth();

  return (
    <SearchPageContext.Provider value={{ flights, setflights }}>
      <Box bgcolor="white">
        <Container>
          <Grid container>
            <Grid item md={5} lg={6} xl={6} p={{ xs: 2, sm: 2, md: 5 }}>
              <Stack justifyContent="center" spacing={3} flex={1} height="100%">
                <Box>
                  <Typography variant="h4" fontWeight="bold" color="primary">
                    Envoyer vos colis
                  </Typography>
                  <Typography variant="h4" fontWeight="bold" color={COLORS.warning}>
                    partout dans le monde !
                  </Typography>
                </Box>
                <Box
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                  }}
                >
                  <Typography variant="body1" fontWeight={400} color="GrayText">
                    Colis Pro est un grand réseau de covaliseurs inscrits et identifiés, plus des annonces pris partout dans le web.
                  </Typography>
                  {!currentUser?.uid ? (
                    <Stack direction="row" spacing={1} mt={3}>
                      <Button fullWidth variant="contained" href="/register/gp" endIcon={<FaSuitcase size={15} />}>
                        Compte GP
                      </Button>
                      <Button fullWidth variant="contained" color="warning" href="/register/client" endIcon={<FaUserAlt size={15} />}>
                        Compte Client
                      </Button>
                    </Stack>
                  ) : (
                    <Button sx={{ mt: 2 }} variant="contained" href="/register/becomeGp" endIcon={<FaSuitcase size={15} />}>
                      Devenir un GP
                    </Button>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              mt={{ xs: -2, sm: -2, md: 0 }}
              overflow="hidden"
              md={7}
              lg={6}
              xl={6}
              mb={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight={200}
            >
              <img src={PresentationImage} alt="presentation" width="90%" />
            </Grid>
          </Grid>
        </Container>
        <Box bgcolor="#F5F5F5" p={2} my={{ lg: 4 }}>
          <Container>
            <SearchPaper />
            <OurValues />
          </Container>
        </Box>
        {/* <Box bgcolor="white">
          <Container>
            <FeaturedFlight />
         
          </Container>
        </Box> */}
        <Box bgcolor="white">
          <Container>
            <EmbbedVideo
              embedId="Jsoht0qcC00"
              step="- Présentation"
              title="Comment ça marche ?"
              description="Vous confiez vos colis à des personnes qui voyagent et vendent des kilos de leur valises. N'hésiter pas à consulter notre chaine youtube."
              button={
                <Button variant="contained" href="/create" endIcon={<MdAdd />}>
                  Créer une annonce
                </Button>
              }
            />
          </Container>
        </Box>
        <Box bgcolor="#F5F5F5">
          <Container>
            <ContactForm />
          </Container>
        </Box>
      </Box>
    </SearchPageContext.Provider>
  );
};

export default Home;
