// Import the functions you need from the SDKs you need
import {
  query,
  doc,
  where,
  getDocs,
  collection,
  limit,
  addDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../config";
import { userDetails } from "../Routes/UserRoutes";

/***
 * Feedbacks
 */

/**
 *
 * @param {*} state le feedback du client
 * @returns {Boolean}  true si bon, false si error
 */
export async function PostAFeedback(state) {
  let res = false;
  await addDoc(collection(db, "feedbacks"), state)
    .then(async (data) => {
      const user = await userDetails(state.gpId);
      if (user?.email) {
        await updateDoc(doc(db, "users", user?.email), {
          feedbacks: arrayUnion(data.id),
        }).catch((error) => console.log("erreur lors de l'ajout de l'id du feedback", error));
        res = true;
      }
    })
    .catch((e) => console.log("error while posting data", e));
  return res;
}

/**
 *
 * @param {String} idReservation l'identifiant de la reservation
 * @returns {Object} le feedback
 */
export async function GetFeedbackFromReservation(idReservation) {
  const q = query(
    collection(db, "feedbacks"),
    where("reservationId", "==", idReservation, limit(1))
  );
  let res = {};
  await getDocs(q).then((value) => value.size > 0 && (res = value.docs[0].data()));
  return res;
}
/**
 * fonction qui retourne les feedbacks d'un gp
 * @param {String} id l'identifiant du gp
 *
 */
export async function getFeedbacksFromGPId(id) {
  const q = query(collection(db, "feedbacks"), where("gpId", "==", id));
  let results = [];

  await getDocs(q).then((values) => {
    values.docs.forEach((value) => results.push({ ...value.data(), id: value.id }));
  });
  return results;
}
