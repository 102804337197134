import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  updatePassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { savePhotoUrl } from "./Routes/UserRoutes";
import { app } from "./config";
import { db } from "./config";

export const auth = getAuth(app);

export const login = async (email, password, setError) => {
  var uid = "";
  await signInWithEmailAndPassword(auth, email, password)
    .then((res) => (uid = res.user.uid))
    .catch((error) => {
      setError(true);
      console.log(error);
    });
  return uid;
};

export const register = async (state) => {
  const document = getDoc(doc(db, "users", state.email));

  var user = undefined;

  await document.then(async (doc) => {
    if (doc.exists()) {
      throw "erreur utilisateur existe";
    } else {
      await createUserWithEmailAndPassword(auth, state.email, state.password1)
        .then((user_) => {
          user = user_;
        })
        .catch((error) => console.log(`error`, error));
    }
  });
  user &&
    (await updateProfile(user.user, {
      displayName: state.firstName.charAt(0).toUpperCase() + state.firstName.slice(1) + " " + state.lastName,
    }));

  const credentials = {
    firstName: state.firstName.charAt(0).toUpperCase() + state.firstName.slice(1),
    lastName: state.lastName,
    email: state.email,
    phone: state.phone,
    country: state.country,
    sex: state.sex,
    birthday: state.birthday.toJSON(),
    followers: [],
    flights: [],
    packages: [],
    reviews: [],
    role: "client",
    freeDelivery: 0,
    createdAt: new Date().toISOString(),
    userId: user.user.uid,
  };

  await setDoc(doc(db, "users", state.email), credentials);
  // .then(() =>
  //   console.log(`user inserted in database`)
  // );

  await sendEmailVerification(user.user).then();
  // .catch(() => console.log("email sent to ", user.user.email));
  return true;
};

export const becomeGp = async (informations, email, identityUrl) => {
  const document = doc(db, "users", email);
  var result = false;
  await updateDoc(document, {
    phoneNumberVerified: false,
    emailVerified: false,
    documentVerified: false,
    identityUrl: identityUrl,
    role: "GP",
    ...informations,
  })
    .then(() => (result = true))
    .catch((error) => console.log(`erreur lors de creation du compte `, error));

  return result;
};

export const registerGP = async (state, identityUrl) => {
  const document = getDoc(doc(db, "users", state.email));

  var user = undefined;

  await document.then(async (doc) => {
    if (doc.exists()) {
      throw "erreur utilisateur existe";
    } else {
      await createUserWithEmailAndPassword(auth, state.email, state.password1)
        .then((user_) => {
          user = user_;
        })
        .catch((error) => console.log(`error`, error));
    }
  });
  user &&
    (await updateProfile(user.user, {
      displayName: state.firstName.charAt(0).toUpperCase() + state.firstName.slice(1) + " " + state.lastName,
    }));

  const credentials = {
    firstName: state.firstName.charAt(0).toUpperCase() + state.firstName.slice(1),
    lastName: state.lastName,
    email: state.email,
    phone: state.phone,
    country: state.country,
    sex: state.sex,
    birthday: state.birthday.toJSON(),
    followers: [],
    flights: [],
    packages: [],
    reviews: [],
    reservations: [],
    role: "GP",
    identityUrl,
    documentIdentity: state.documentIdentity,
    emailVerified: false,
    phoneNumberVerified: false,
    documentVerified: false,
    whatsapp1: state.whatsapp1,
    identityNumber: state.identityNumber,
    phone2: state?.phone2,
    whatsapp2: state.whatsapp2,
    createdAt: new Date().toISOString(),
    userId: user.user.uid,
  };

  await setDoc(doc(db, "users", state.email), credentials);
  await sendEmailVerification(user.user)
    .then()
    .catch(() => console.log("email sent"));
  return true;
};

export const logout = () => {
  signOut(auth).catch((error) => console.log(`error`, error));
};

/**
 * Verifie si un email existe dans la base auth
 * @param {String} email
 * @returns
 */
export async function verifyIfUserExists(email) {
  var exists = false;
  await fetchSignInMethodsForEmail(auth, email)
    .then((methods) => (exists = methods.length > 0))
    .catch((error) => {
      throw ("erreur lors de la verification", error);
    });

  return exists;
}

export function useAuth() {
  const [currentUser, setCurrentUser] = useState(undefined);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => setCurrentUser(user));

    return unsubscribe;
  }, []);

  return currentUser;
}

export function AuthWithPhoneNumber(phoneNumber) {
  auth.useDeviceLanguage();
  window.recaptchaVerifier = new RecaptchaVerifier(
    "sign-in-button",
    {
      size: "invisible",
      callback: (response) => {
        console.log("response", response);
      },
    },
    auth
  );
  signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier).then((confirmationResult) => {
    window.confirmationResult = confirmationResult;
    console.log("confirmationResult", confirmationResult);
  });
}

/**
 * functions
 */
export async function setPhotoUrl(url) {
  var user = auth.currentUser;
  await updateProfile(user, { photoURL: url })
    .then(() => savePhotoUrl(url, user?.email))
    .catch((error) => console.log("erreur lors de l'ajout de la photo", error));
}

/**
 * verify email and password
 */

export async function sendResetPassword(email) {
  await sendPasswordResetEmail(auth, email);
}

/**
 * Fonction qui est appellée quand le mot de passe est reinitialisé
 * @param {*} oobCode
 * @param {*} newPassword
 */
export async function handlePasswordReset(oobCode, newPassword) {
  await confirmPasswordReset(auth, oobCode, newPassword)
    .then(() => {
      return true;
    })
    .catch((err) => console.log("error ", err));
}

export async function resendEmailVerification(email) {
  let user = auth.currentUser;
  await sendEmailVerification(user, null)
    .then(() => console.log("email sent to ", user.email))
    .catch((err) => console.log("err", err));
}

export async function verifyEmail(oobCode) {
  await applyActionCode(auth, oobCode)
    .then(async () => {
      const docRef = doc(db, "users", auth.currentUser.email);
      await updateDoc(docRef, { emailVerified: true }).then(() => console.log("email verified on db"));
    })
    .then(() => console.log("email verified"));
}

/**
 *  mettre a jour le mot de passe
 */
export async function handlePasswordUpdate(newPassword) {
  await updatePassword(auth.currentUser, newPassword)
    .then(() => {
      console.log("Password Updated");
    })
    .catch((error) => console.log("error ", error));
}

/**
 * Authentification par les reseaux
 */

export async function AuthenticateWithSocials(providerType) {
  let provider;

  if (providerType === "google") provider = new GoogleAuthProvider();
  if (providerType === "facebook") provider = new FacebookAuthProvider();

  // await signInWithRedirect(auth, provider);
  const user = await signInWithPopup(auth, provider);

  let res = { uid: user.user.uid, getSocialInfos: false };
  console.log("res", user);

  //test if user connected via google has an account
  const userRef = doc(db, "users", user.user.email);

  await getDoc(userRef).then(async (data) => {
    if (!data.exists()) {
      //create a new user account in database

      const credentials = {
        firstName: user.user.displayName.split(" ")[0],
        lastName: user.user.displayName.split(" ")[1],
        email: user.user.email,
        phone: user.user.phoneNumber,
        country: "unknown",
        sex: "unknown",
        birthday: "unknown",
        followers: [],
        flights: [],
        packages: [],
        reviews: [],
        role: "client",
        freeDelivery: 0,
        createdAt: new Date().toISOString(),
        userId: user.user.uid,
      };

      await setDoc(doc(db, "users", user.user.email), credentials).then(() => {
        // console.log(`user inserted in database`);
        res = { ...res, getSocialInfos: true };
      });
    } else {
      // console.log("enter exists");
    }
  });

  return res;
}

export async function UpdateSocialInfos(email, userInfos) {
  const userRef = doc(db, "users", email);

  let done = true;

  await updateDoc(userRef, { ...userInfos, emailVerified: true, provider: "google" }).catch(() => (done = false));

  return done;
}
