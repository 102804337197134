import { Button, Divider, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { FaCommentDots, FaIdCard, FaPhoneAlt, FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import COLORS from "../../colors";

import ProfilPic from "../../Images/profile.svg";
import { ViewContext } from "../Pages/View";
import VerifiedCondition from "./VerifiedCondition";

const ProfilDescriptorSkeleton = () => {
  return (
    <Box>
      <Stack spacing={2} alignItems="center" flex={1}>
        <Skeleton variant="circular" width={100} height={100} />
        <Skeleton width="90%" height={40} />
        <Skeleton width="90%" height={40} />
      </Stack>
    </Box>
  );
};

const ProfilDescriptor = ({ state }) => {
  const { visitProfil, loading, gpInformations } = useContext(ViewContext);
  return (
    <Paper sx={{ p: { xs: 2, md: 3 }, boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }} elevation={0}>
      {loading ? (
        <ProfilDescriptorSkeleton />
      ) : (
        <Box>
          <Typography variant="h6" color="GrayText">
            Transporteur
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Stack direction={{ xs: "row", sm: "row", md: "column" }}>
            <Box flex={{ xs: 2, sm: 1 }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                m={2}
              >
                <img
                  width="100%"
                  alt={gpInformations?.photoUrl ? gpInformations?.photoUrl : ProfilPic}
                  src={gpInformations?.photoUrl ? gpInformations?.photoUrl : ProfilPic}
                  style={{ borderRadius: 5 }}
                />
                <Typography variant="body2">
                  {state.publisher.firstName + " " + state.publisher.lastName}
                </Typography>
                {state.byColisPro === true && (
                  <Typography variant="caption" color={COLORS.warning}>
                    Publié par Colis-pro
                  </Typography>
                )}
              </Stack>
            </Box>
            <Box flex={{ xs: 3, sm: 1 }}>
              {/* <Divider sx={{ my: 1 }} /> */}
              <Stack spacing={2} my={2}>
                <Button variant="outlined" fullWidth color="primary" endIcon={<FaCommentDots />}>
                  Contacter
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  color="warning"
                  endIcon={<FaUser />}
                  onClick={visitProfil}
                >
                  Voir profil
                </Button>
              </Stack>
              {/* <Divider sx={{ my: 2 }} /> */}
              {state.byColisPro === true ? (
                <Typography variant="caption"> Annonce via {state?.announceOrigin}</Typography>
              ) : (
                <Box color={COLORS.black}>
                  <Typography variant="body1">Informations vérifiées</Typography>
                  <Box my={1}>
                    <VerifiedCondition
                      label="Piéce d'identité"
                      icon={<FaIdCard />}
                      condition={gpInformations?.documentVerified}
                    />

                    <VerifiedCondition
                      label="Email"
                      icon={<MdEmail />}
                      condition={gpInformations?.emailVerified}
                    />
                    <VerifiedCondition
                      label="Téléphone"
                      icon={<FaPhoneAlt />}
                      condition={gpInformations?.phoneNumberVerified}
                    />

                    <VerifiedCondition
                      label="Billet d'avion"
                      icon={<FaPhoneAlt />}
                      condition={state?.flightTicketConfirmed}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default ProfilDescriptor;
