import { Icon, Stack, Typography } from "@mui/material";
import React from "react";
import { ImCross } from "react-icons/im";

const VerifiedCondition = ({ condition, icon, label }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" m={1}>
      <Icon fontSize="18px" color={condition ? "primary" : "error"}>
        {condition ? icon : <ImCross />}
      </Icon>
      <Typography color={condition ? "primary" : "error"}>
        {label} {!condition && " (non)"}
      </Typography>
    </Stack>
  );
};

export default VerifiedCondition;
