import { Paper, Stack, Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { ProfileDetailsContext } from "../../Pages/ProfileDetails";
import UserFeedback, { UserFeedbackSkeleton } from "../UserFeedback";
import { getFeedbacksFromGPId } from "../../../firebase/Routes/FeedBacksRoute";

export const GPFeedbacks = () => {
  const { user } = useContext(ProfileDetailsContext);

  const [feedbacks, setfeedbacks] = useState([]);
  const [loading, setloading] = useState(true);

  async function getAllGPFeedbacks() {
    const results = await getFeedbacksFromGPId(user?.userId);
    // console.log("results", results);
    setfeedbacks(results);
    setloading(false);
  }

  useEffect(() => {
    user?.userId && getAllGPFeedbacks();
  }, [user]);

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center" my={3}>
        <FaStar color="grayText" />
        <Typography fontWeight="bold" variant="h6" color="primary" flexGrow={1}>
          Evaluations
        </Typography>
      </Stack>
      <Stack bgcolor={feedbacks.length > 0 && "white"} px={2} borderRadius={2}>
        <Stack spacing={2} py={2}>
          {loading ? (
            ["1", "2", "3"].map((feedback) => <UserFeedbackSkeleton key={feedback} />)
          ) : (
            <Box>
              {feedbacks.length > 0 ? (
                <Stack spacing={2} divider={<Divider light />}>
                  {feedbacks.map((feedback, index) => (
                    <UserFeedback data={feedback} key={index} />
                  ))}
                </Stack>
              ) : (
                <Paper
                  sx={{
                    flex: 1,
                    boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)",
                    p: 2,
                    my: 2,
                    textAlign: "center",
                  }}
                  elevation={0}
                >
                  <Typography color="GrayText">Pas d'évaluations.</Typography>
                </Paper>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
