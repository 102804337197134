import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../colors";

export const InformationViewer = ({ icon, label, information }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Stack direction="row" spacing={1}>
        <IconButton>{icon}</IconButton>

        <Typography gutterBottom color={COLORS.black} fontWeight={555} variant="body1" flexGrow={1}>
          {label}
        </Typography>
        <Typography variant="body1" color="GrayText">
          {information}
        </Typography>
      </Stack>
      <Divider sx={{ my: 0.5 }} />
    </Grid>
  );
};
