import { Avatar, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { RiMedal2Line } from "react-icons/ri";

export const TopHeader = ({ user, loading, getAvatar }) => {
  const grade = user?.flights.length > 5 ? "Professionnel" : "DEBUTANT";

  return (
    <Box flex={1} mr={{ xs: 0, sm: 0, md: 2 }}>
      <Paper elevation={0} sx={{ boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }}>
        <Stack p={2} direction="row" spacing={2} flex={1}>
          <Avatar alt={user?.photoUrl} src={user?.photoUrl}>
            {getAvatar()}
          </Avatar>
          <Stack direction="row" alignItems="center" justifyContent="space-between" flex={1}>
            <Box>
              <Typography variant="h6" color="#2B3445" fontWeight={500}>
                {loading ? <Skeleton width={100} /> : user?.firstName + " " + user?.lastName}
              </Typography>
              <Typography variant="body2" color="primary">
                {loading ? <Skeleton width={100} /> : user?.country}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography color="GrayText" letterSpacing={1} variant="body2" fontWeight={300}>
                {user?.role === "GP" ? "GP " + grade : "CLIENT"}
              </Typography>
              <RiMedal2Line size={15} color="goldenrod" />
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};
