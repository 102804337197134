import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { FaEdit } from "react-icons/fa";
import { MdCancel, MdExpandMore } from "react-icons/md";
import COLORS from "../../../colors";
import moment from "moment";
import Flight from "../../Flight";

export const Publication = ({ data, editPublication, ShowDeleteDialog }) => {
  const calculateWeight = () => {
    let weight = 0;
    data.suitcases.map((suitecase) => (weight += suitecase.weight));
    return weight;
  };
  return (
    <Paper sx={{ flex: 1, boxShadow: "0px 1px 3px rgba(3, 0, 71, 0.2)" }} elevation={0}>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Grid container spacing={1} display="flex" color={COLORS.black} alignItems="center">
            <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
              <Typography variant="body2" fontWeight="bold" color="GrayText">
                {data?.departure?.name + " - " + data?.destination.name}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Typography variant="body2" textAlign="center">
                {moment(data.distributionDate).format("D/MMM")}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
              <Typography variant="body2" textAlign="right">
                {calculateWeight() + " kg"}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Typography variant="body2" fontWeight={555} color={COLORS.warning} textAlign="right" mr={0.3}>
                {data.prices.pricePerKG} {data?.currency ? data.currency : "€"}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="grayText" fontWeight="bold" gutterBottom variant="body1">
            Visuel :
          </Typography>
          <Flight data={data} />
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button
              variant="outlined"
              size="medium"
              color="error"
              disabled={moment(data.distributionDate).subtract(2, "days").isSameOrBefore(moment())}
              endIcon={<MdCancel />}
              onClick={() => ShowDeleteDialog(data.id)}
            >
              Annuler vol
            </Button>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              disabled={moment(data.distributionDate).isSameOrBefore(moment())}
              endIcon={<FaEdit />}
              onClick={() => editPublication(data)}
            >
              Modifier
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
